import {
  Component,
  OnInit,
  NgModule,
  Input,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  createDS,
  PblColumn,
  PblColumnDefinition,
  PblDataSource,
  PblDataSourceFactory,
  PblDataSourceTriggerChangedEvent,
  PblNgridColumnDefinitionSet,
  PblNgridComponent,
  PblNgridModule,
} from '@pebula/ngrid';
import { map, merge, Observable, of, Subscription, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { PblNgridCheckboxModule } from '@pebula/ngrid-material/selection-column';
import { SelectItem, UiModule } from '@smiths/ui';
import { PblNgridCellEvent, PblNgridTargetEventsModule } from '@pebula/ngrid/target-events';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { RouterModule } from '@angular/router';
import { PblNgridMatSortModule } from '@pebula/ngrid-material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PblNgridBlockUiModule } from '@pebula/ngrid/block-ui';
import { animate, style, transition, trigger } from '@angular/animations';
import { PblNgridCellTooltipModule } from '@pebula/ngrid-material/cell-tooltip';
import { StrapiPaginatedResponse, StrapiSearch } from '@smiths/data-access';
import { formatDate } from '@angular/common';

// export type TableColumn<T> = PblColumnDefinition & { prop: keyof T};
export type TableActionType = 'edit' | 'delete' | 'view';
export type CellType = 'text' | 'image' | 'enum' | 'array' | 'date' | 'date-array';
export type TableItemFn<T> = (options?: TableRequestOptions) => T[] | Observable<StrapiPaginatedResponse<T>>;
export interface TableColumn {
  prop: string;
  label?: string;
  width?: string;
  transform?: (value: any, row?: any, col?: PblColumnDefinition | PblColumn) => any;
  classList?: string;
  type?: CellType;
  searchable?: boolean;
  sortable?: boolean;
  enumItems?: SelectItem<any>[];
  align?: 'left' | 'center' | 'right';
  /*
  Default is 'contains'
   */
  searchStrategy?: 'contains' | 'equal';
  transformApplyEnumItems?: boolean;
  disableNullTransform?: boolean;
  onClick?: (value: any, row: any, e: MouseEvent) => void;
  dynamicClass?: (value: any, row: any) => string;
  customSearch?: (value: any) => StrapiSearch | null;
}
export interface RefreshData {
  search?: { [p: string]: TableFormControl };
  pagination?: {
    perPage: number;
    page: number;
  };
}
export interface TableRequestOptions {
  search?: { strategy?: 'eq' | 'contains' | 'lte' | 'gte'; key: string; term: string }[];
  sort?: { [p: string]: 'asc' | 'desc' };
  pagination?: {
    perPage: number;
    page: number;
  };
}
export interface TableAction<T> {
  icon?: 'table-edit' | 'table-delete' | 'eye-show' | string;
  text?: string;
  dynamicText?: (value: T) => string;
  /*
  If you return observable to the action, it will automatically refresh the table
   */
  action: (item: T) => Observable<any> | void;
}
export interface TableBulkAction<T> {
  text: string;
  /*
  If you return observable to the action, it will automatically refresh the table
   */
  action: (items: T[]) => Observable<any> | void;
}
type TableFormControl = FormControl & { column?: TableColumn };

const START_DATE_SUFFIX = '.start_date';
const END_DATE_SUFFIX = '.end_date';

@Component({
  selector: 'feature-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [style({ opacity: 0 }), animate('0.1s ease', style({ opacity: 0.8 }))]),
      transition(':leave', [style({ opacity: 0.8 }), animate('0.1s ease', style({ opacity: 0 }))]),
    ]),
  ],
})
export class TableComponent<T> implements OnInit, OnDestroy, OnChanges {
  @ViewChild('table') table!: PblNgridComponent;

  @Input() columns!: TableColumn[];
  @Input() hiddenColumns?: string[] = [];
  @Input() itemsFn!: TableItemFn<T> | undefined;
  @Input() rowsToView?: number;
  @Input() title = '';
  @Input() selectable = true;
  @Input() exportable = true;
  @Input() showId = true;
  @Input() showActions = true;
  @Input() showPagination = true;
  @Input() actions: TableAction<T>[] = [
    {
      icon: 'table-edit',
      action: (item) => {
        //
      },
    },
    {
      icon: 'table-delete',
      action: (item) => {
        //
      },
    },
  ];
  @Input() bulkActions: TableBulkAction<T>[] = [
    {
      text: 'Delete',
      action: (items) => {
        //
      },
    },
  ];
  @Input() addButtonText = 'Create Item';
  @Input() addButtonRouteLink?: string[];
  @Input() showMoreButtonText?: string;
  @Input() showMoreButtonRouteLink?: string[];

  @Input() hideAddButton = false;
  @Input() hideBulkActionDropdown = false;
  @Input() actionColumnSize = '96px';
  @Input() showTotalResult = false;

  @Output() searchChange = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() export = new EventEmitter();

  dataSourceFactory!: PblDataSourceFactory<T>;
  dataSource!: PblDataSource<T, any, PblDataSourceTriggerChangedEvent<any>>;
  tableDefinition!: PblNgridColumnDefinitionSet;

  // currentActions: { [key in TableActionType]: { single?: boolean; bulk?: boolean } } = {
  //   edit: {},
  //   delete: {},
  //   view: {},
  // };
  actionDropdownItems: SelectItem<TableBulkAction<T>>[] = [];

  searchControlsGroup = new FormGroup({});
  currentPage = 1;
  perPage = 10;
  totalPages = 1;
  totalItems = 0;
  perPageControl = new FormControl(10);
  perPageOptions: SelectItem<number>[] = [
    { text: '10', value: 10 },
    { text: '25', value: 25 },
    { text: '50', value: 50 },
  ];

  loading = false;
  subs = new Subscription();

  ngOnInit(): void {
    this.initActions();
    this.initTableDefinition();
    this.initDatabase();
    this.subs.add(
      merge(this.dataSource.onRenderDataChanging, this.dataSource.onSourceChanging).subscribe(() => {
        this.loading = true;
      })
    );
    this.subs.add(
      merge(
        this.dataSource.onError,
        this.dataSource.onSourceChanged,
        this.dataSource.onRenderedDataChanged
      ).subscribe(() => {
        this.loading = false;
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemsFn) {
      this.triggerDatabaseRefresh();
    }
  }

  onRowClick(e: any) {
    // console.log(e);
  }

  onPageChange(e: number) {
    this.currentPage = e;
    this.triggerDatabaseRefresh();
  }

  onPerPageChange(e: number) {
    this.perPage = e;
    this.currentPage = 1;
    this.triggerDatabaseRefresh();
  }

  onAction(e: MouseEvent, item: T, action: TableAction<T>) {
    e.stopPropagation();
    const actionResult = action.action(item);
    if (actionResult instanceof Observable) {
      actionResult.pipe(take(1)).subscribe(() => {
        this.triggerDatabaseRefresh();
      });
    }
  }

  onBulkAction(bulkAction: TableBulkAction<T>) {
    const selectedItems = this.dataSource.selection.selected;
    if (selectedItems.length > 0) {
      const actionResult = bulkAction.action(selectedItems);
      if (actionResult instanceof Observable) {
        actionResult.pipe(take(1)).subscribe(() => {
          this.triggerDatabaseRefresh();
          this.dataSource.selection.clear();
        });
      }
    }
  }

  onAdd() {
    this.add.emit();
  }
  onExport() {
    this.export.emit();
  }

  onCellClick(value: any, row: T, e: MouseEvent, clickFn: any) {
    if (clickFn) {
      clickFn(value, row, e);
      e.stopPropagation();
    }
  }

  triggerDatabaseRefresh() {
    if (!this.dataSource) {
      return;
    }

    this.loading = true;
    let search: any = {};
    for (const key of Object.keys(this.searchControlsGroup.value)) {
      const value = this.searchControlsGroup.value[key];
      if (value) {
        search = {
          ...search,
          [key]: value,
        };
      }
    }

    this.dataSource.refresh({
      // search: search,
      search: this.searchControlsGroup.controls,
      pagination: this.showPagination
        ? {
            page: this.currentPage - 1,
            perPage: this.perPage,
          }
        : this.rowsToView
        ? {
            page: 0,
            perPage: this.rowsToView,
          }
        : undefined,
    } as RefreshData);
  }

  getTooltipMessage(event: PblNgridCellEvent<T>): string {
    return `${event.cellTarget.innerText}`;
  }

  resetPagination() {
    this.currentPage = 1;
  }

  private initActions() {
    // for (const actionKey of this.actions) {
    //   this.currentActions[actionKey as TableActionType].single = true;
    // }
    for (const action of this.bulkActions) {
      // this.currentActions[action as TableActionType].bulk = true;
      this.actionDropdownItems.push({ text: this.toTitleCase(action.text), value: action });
    }
  }

  private initTableDefinition() {
    this.tableDefinition = {
      table: {
        cols: this.columns?.map((col) => {
          let searchControl: TableFormControl | undefined = undefined;
          let endDateSearchControl: TableFormControl | undefined = undefined;
          if (col.searchable) {
            searchControl = new FormControl('');
            searchControl.column = col;
            if (col.type === 'date-array' || col.type === 'date') {
              endDateSearchControl = new FormControl('');
              endDateSearchControl.column = col;
              this.registerSearchControl(`${col.prop}${START_DATE_SUFFIX}`, searchControl);
              this.registerSearchControl(`${col.prop}${END_DATE_SUFFIX}`, endDateSearchControl);
            } else {
              this.registerSearchControl(col.prop, searchControl);
            }
          }
          return {
            ...col,
            sort: col.type === 'image' ? false : col.sortable ?? true,
            transform: (value, row, column) => {
              const enumTransformer = (value: any, row: any, column: any) => {
                let newValue = value;
                if (col.transformApplyEnumItems) {
                  newValue = col.enumItems?.find((x) => x.value === value)?.text ?? value;
                }
                return col.transform ? col.transform(newValue, row, column) : newValue;
              };

              const customPropFn = (row: any, column: any) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return col.transform ? col.transform(undefined, row, column) : '-';
              };

              if (this.hasProperty(row, col.prop)) {
                // if (col.customProp) {
                if (column && col.dynamicClass) {
                  column.data.dynamicClassList = col.dynamicClass(value, row);
                }
                const isNull = value === undefined || value === null || value === '';
                const newValue = isNull ? '-' : value;
                return !isNull || col.disableNullTransform ? enumTransformer(value, row, column) : newValue;
              } else {
                return customPropFn(row, column);
              }
            },
            data: {
              classList: col.classList,
              searchControl: searchControl,
              enumItems: col.enumItems,
              align: col.align ?? 'left',
              endDateSearchControl: endDateSearchControl,
              onClick: col.onClick,
              customSearch: col.customSearch,
            },
          };
        }),
      },
    };
    if (this.showId) {
      this.tableDefinition.table.cols?.unshift({
        prop: 'id',
        width: '56px',
        label: 'ID',
        sort: true,
        data: { align: 'left' },
      });
    }
    if (this.selectable) {
      this.tableDefinition.table.cols?.unshift({ prop: 'selection', width: '24px' });
    }
    if (this.showActions) {
      this.tableDefinition.table.cols?.push({
        prop: 'action',
        width: this.actionColumnSize,
        label: 'Action',
      });
    }
  }

  private initDatabase() {
    this.dataSourceFactory = createDS<T>()
      .setCustomTriggers('filter', 'pagination', 'sort')
      .onTrigger((e) => {
        // console.log(e);
        const refreshData = e.data.curr as unknown as RefreshData | undefined;
        const data = {
          pagination: refreshData?.pagination,
          sort:
            e.sort.curr?.column?.id && e.sort.curr?.sort?.order
              ? { [e.sort.curr.column.id]: e.sort.curr.sort.order }
              : undefined,
          search: refreshData?.search
            ? Object.keys(refreshData.search).map((key) => {
                const control = refreshData.search![key] as TableFormControl;
                const currentColumn = control.column!;
                if (currentColumn.customSearch) {
                  return currentColumn.customSearch(control.value) ?? { term: '', key: '' };
                }
                let strategy: string | undefined;
                let term: string | undefined;
                let keyToUse = key;
                if (currentColumn.type === 'date') {
                  if (keyToUse.endsWith(START_DATE_SUFFIX)) {
                    keyToUse = keyToUse.replace(START_DATE_SUFFIX, '');
                  } else if (keyToUse.endsWith(END_DATE_SUFFIX)) {
                    keyToUse = keyToUse.replace(END_DATE_SUFFIX, '');
                  }
                }
                if (currentColumn.type === 'date-array' || currentColumn.type === 'date') {
                  term = control.value ? formatDate(control.value, 'yyyy-MM-dd', 'en') : '';
                } else {
                  term = control.value;
                }
                if (
                  key.endsWith(START_DATE_SUFFIX) &&
                  (currentColumn.type === 'date-array' || currentColumn.type === 'date')
                ) {
                  strategy = 'gte';
                } else if (
                  key.endsWith(END_DATE_SUFFIX) &&
                  (currentColumn.type === 'date-array' || currentColumn.type === 'date')
                ) {
                  strategy = 'lte';
                } else {
                  strategy = currentColumn.searchStrategy === 'equal' ? 'eq' : 'contains';
                }

                return {
                  key: keyToUse,
                  term,
                  strategy,
                };
              }, {} as any)
            : undefined,
        } as TableRequestOptions;
        if (!data.pagination) {
          if (this.showPagination) {
            data.pagination = {
              page: 0,
              perPage: this.perPage,
            };
          } else if (this.rowsToView) {
            data.pagination = {
              page: 0,
              perPage: this.rowsToView,
            };
          }
        }
        const items = this.itemsFn ? this.itemsFn(data) : [];
        let observable: Observable<T[]>;
        if (items instanceof Array) {
          observable = of(items);
        } else {
          observable = items.pipe(
            tap((result) => {
              if (result.total_count) {
                this.totalItems = result.total_count;
                this.totalPages = Math.ceil(result.total_count / this.perPage);
              } else {
                this.totalItems = result.list?.length ?? 0;
                this.totalPages = 1;
              }
            }),
            map((x) => x.list)
          );
        }
        return observable.pipe();
      });
    this.dataSource = this.dataSourceFactory.create();
  }

  private registerSearchControl(prop: string, control: FormControl) {
    this.searchControlsGroup.addControl(prop, control);
    this.subs.add(
      control.valueChanges
        .pipe(
          tap(() => {
            this.searchChange.emit();
          }),
          distinctUntilChanged(),
          debounceTime(450)
        )
        .subscribe((value) => {
          this.triggerDatabaseRefresh();
        })
    );
  }

  private toTitleCase(text: string) {
    return text.replace(/\w\S*/g, (str) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase());
  }

  private hasProperty(item: T, prop: string) {
    if (prop.includes('.')) {
      const propSplit = prop.split('.');
      const lastKey = propSplit.pop()!;
      const lastObject = propSplit.reduce((pre, cur) => pre[cur], item as any);
      return lastObject ? lastKey in lastObject : false;
    } else {
      return prop in item;
    }
  }
}

@NgModule({
  declarations: [TableComponent],
  imports: [
    PblNgridModule,
    CommonModule,
    PblNgridCheckboxModule,
    UiModule,
    PblNgridTargetEventsModule,
    MatButtonModule,
    RouterModule,
    PblNgridMatSortModule,
    MatProgressBarModule,
    MatTooltipModule,
    PblNgridBlockUiModule,
    PblNgridCellTooltipModule,
  ],
  exports: [TableComponent],
})
export class TableComponentModule {}
