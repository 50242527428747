<ng-template *ngIf="isDesktop" [ngTemplateOutlet]="header"></ng-template>
<mat-sidenav-container class="app-sidebar" [ngClass]="{ 'mobile-view': !isDesktop }">
  <mat-sidenav
    #sidebar
    [mode]="isDesktop ? 'side' : 'over'"
    [opened]="isDesktop"
    (mouseleave)="onMouseLeave()"
  >
    <div class="flex h-full">
      <div class="level-one">
        <div
          *ngFor="let link of links"
          class="link-box"
          (mouseenter)="onMouseEnter(link)"
          routerLink="/{{ link.url }}"
          routerLinkActive="active"
        >
          <div>
            <mat-icon [svgIcon]="$any(link.icon)"></mat-icon>
            <p class="mb-0 text-sm">{{ link.title }}</p>
          </div>
        </div>
      </div>
      <div class="splitter"></div>
      <div class="level-two" [@sidebarToggle]="isSecondSidebarOpen ? 'opened' : 'closed'">
        <mat-list role="list" class="pt-0">
          <mat-list-item
            *ngFor="let child of currentLink?.children"
            role="listitem"
            routerLink="/{{ currentLink?.url }}/{{ child.url }}"
            routerLinkActive="active"
          >
            <div class="child-link-box">
              <span class="text-gray-middle font-bold">{{ child.title }}</span>
              <mat-icon svgIcon="chevron-right"></mat-icon>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    class="bg-gray-100"
    [@contentToggle]="isSecondSidebarOpen ? 'opened' : 'closed'"
    [ngClass]="{ 'ml-0': !isDesktop }"
  >
    <ng-template *ngIf="!isDesktop" [ngTemplateOutlet]="header"></ng-template>
    <div class="app-content-wrapper">
      <div class="app-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #header>
  <div class="app-header flex items-center justify-between px-6">
    <p *ngIf="isDesktop; else sidebarToggle" class="lexend-deca text-primary text-xl">SMITHS</p>
    <ng-template #sidebarToggle>
      <mat-icon svgIcon="sidebar-toggle" (click)="sidebar.open()" class="cursor-pointer"></mat-icon>
    </ng-template>
    <div class="flex items-center justify-between gap-10">
      <div (click)="onNotificationClick()" class="flex flex-row relative cursor-pointer">
        <mat-icon svgIcon="notification"></mat-icon>
        <ng-container *ngIf="unreadCount$ | async as unreadCount">
          <div
            *ngIf="unreadCount"
            class="
            absolute
            bg-orange
            w-4
            h-4
            text-center
            rounded-full
            text-white
            font-medium
            -right-3
            -top-3
            select-none
          "
          >
            <p class="-mt-0.5">{{ unreadCount }}</p>
          </div>
        </ng-container>
      </div>
      <mat-icon svgIcon="profile" class="cursor-pointer" (click)="openProfile()"></mat-icon>
    </div>
  </div>
</ng-template>
