<ngx-file-drop
  (onFileDrop)="onSelectImage($event)"
  [multiple]="false"
  accept=".png,.jpg"
  class="image-uploader"
>
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <div class="flex items-center gap-4 mb-6 relative">
      <mat-spinner *ngIf="loading" [strokeWidth]="2" diameter="90" class="absolute left-0"></mat-spinner>
      <div
        class="img-wrapper"
        (click)="control.value?.url ? onDisplay(control.value.url) : !readonly ? openFileSelector() : null"
        [ngClass]="{
          'border-transparent': loading,
          'border-error': !this.loading && this.control.touched && !this.control.valid
        }"
      >
        <ng-container *ngIf="control.value; else IconPlaceholder">
          <img [src]="apiBaseUrl + control.value.url" />
        </ng-container>
      </div>
      <div class="text-sm font-medium grid grid-flow-row gap-y-1">
        <ng-container *ngIf="control.value; else noPhoto">
          <p *ngIf="!readonly" (click)="openFileSelector()" class="text-primary-dark cursor-pointer">{{ editText }}</p>
          <p *ngIf="!readonly" (click)="onDelete()" class="text-secondary-900 cursor-pointer">{{ deleteText }}</p>
        </ng-container>
        <ng-template #noPhoto>
          <p *ngIf="!readonly" (click)="openFileSelector()" class="text-primary-dark cursor-pointer">{{ addText }}</p>
        </ng-template>
        <ng-template #IconPlaceholder>
          <div class="inset-5 absolute">
            <mat-icon *ngIf="placeholderIcon" class="w-full h-full text-gray-500" [svgIcon]="placeholderIcon"></mat-icon>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</ngx-file-drop>
