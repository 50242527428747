<p class="lexend-deca text-xl text-gray-800 mb-7">Reset password</p>
<div class="form-wrapper grid grid-flow-row gap-8">
  <ui-field
    [control]="$any(formGroup.controls.oldPassword)"
    label="Old password"
    inputType="password"
  ></ui-field>
  <div class="grid grid-flow-row gap-4">
    <ui-field
      [control]="$any(formGroup.controls.newPassword)"
      label="New password"
      inputType="password"
      customErrorText="Password should be at least 8 characters"
    ></ui-field>
    <ui-field
      [control]="$any(formGroup.controls.confirmNewPassword)"
      label="Confirm new password"
      inputType="password"
    ></ui-field>
  </div>
</div>
<div class="flex justify-end mt-4">
  <ui-button (click)="onSave()" label="Set new password" [isLoading]="loading"></ui-button>
</div>
