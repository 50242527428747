import { AuthData } from '../../../models/auth.model';

export class LoginAction {
  static readonly type = '[Auth] Login';
  constructor(
    public username: string,
    public password: string,
    public rememberMe: boolean,
    public fcmToken?: string
  ) {}
}

export class LogoutAction {
  static readonly type = '[Auth] Logout';
}

export class ClearStorageAction {
  static readonly type = '[Auth] Clear Storage';
}

export class SaveAuthDataAction {
  static readonly type = '[Auth] Save Auth Data';
  constructor(public data: AuthData) {}
}

export class UpdateProfileAvatarAction {
  static readonly type = '[Auth] Update Profile Avatar';
  constructor(public data: number) {}
}
