import { map, Observable, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Component, NgModule, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationApiService, Notification } from '@smiths/data-access';
import { LoaderComponentModule, UiButtonComponentModule } from '@smiths/ui';
import { ActivatedRoute, Router } from '@angular/router';

const MAX_NOTIFICATIONS_COUNT = 10;

@Component({
  selector: 'feature-notification',
  templateUrl: './notification-mini-list.component.html',
  styleUrls: ['./notification-mini-list.component.scss'],
})
export class NotificationMiniListComponent {
  @Output() goToSetting = new EventEmitter();

  notifications$: Observable<Notification[]>;

  constructor(
    public dialogRef: MatDialogRef<never>,
    private notificationApi: NotificationApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.notifications$ = this.notificationApi
      .getNotification(MAX_NOTIFICATIONS_COUNT)
      .pipe(map((result) => result.list));
  }

  onActionClick(notification: Notification) {
    if (notification.new_user !== null) {
      this.router.navigate(['users', 'edit', notification.new_user]);
    } else if (notification.request !== null) {
      this.router.navigate(['vault', 'requests', 'details', notification.request.id]);
    }
    this.dialogRef.close();
  }
  viewAllNotifications() {
    this.router.navigate(['notifications']);
    this.dialogRef.close();
  }
}

@NgModule({
  declarations: [NotificationMiniListComponent],
  imports: [CommonModule, UiButtonComponentModule, LoaderComponentModule],
  exports: [NotificationMiniListComponent],
})
export class NotificationMiniListComponentModule {}
