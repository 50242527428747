<ng-container *ngIf="pageCount">
  <div *ngIf="!(autoHide && pageCount === 1)" class="flex text-gray-caption text-base w-full justify-center items-center gap-2">
    <button
      mat-button
      class="mat-button-custom navigate-button"
      (click)="onPrevious()"
      [disabled]="page === 1"
    >
      <mat-icon svgIcon="arrow-left"></mat-icon>
    </button>
    <ng-container *ngFor="let i of pageArray; index as index">
      <p class="ellipsis-gap" *ngIf="i === pageCount && rightEllipsis">...</p>
      <div
        [ngClass]="page === i ? 'selected-page' : ''"
        class="page"
        (click)="onPageSelect(i)"
      >
        <p>{{ i }}</p>
      </div>
      <p class="ellipsis-gap" *ngIf="i === 1 && leftEllipsis">...</p>
    </ng-container>
    <button
      mat-button
      class="mat-button-custom navigate-button"
      (click)="onNext()"
      [disabled]="page === pageCount"
    >
      <mat-icon svgIcon="arrow-right"></mat-icon>
    </button>
  </div>
</ng-container>

