import { Component, NgModule, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl } from '@angular/forms';
import { map, Observable, take } from 'rxjs';
import { UiModule } from '@smiths/ui';
import { DialogService } from '../dialog/dialog.service';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';

@Component({
  selector: 'feature-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @Input() latControl = new FormControl('');
  @Input() lngControl = new FormControl('');
  @Input() addressControl = new FormControl('');
  center: google.maps.LatLngLiteral = { lat: 29.378586, lng: 47.990341 };

  constructor(private dialogService: DialogService) {
  }

  ngOnInit() {
    if (this.latControl.value && this.lngControl.value) {
      this.center = {
        lat: this.latControl.value,
        lng: this.lngControl.value,
      };
    }
  }

  openMapDialog() {
    const lat = +this.latControl.value;
    const lng = +this.lngControl.value;
    this.dialogService
      .map({ lat, lng, center: this.center })
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.latControl.setValue(result.lat);
          this.lngControl.setValue(result.lng);
          this.addressControl.setValue(result.address);
          this.center = { lat: result.lat, lng: result.lng };
        }
      });
  }
}

@NgModule({
  declarations: [MapComponent],
  imports: [CommonModule, UiModule, GoogleMapsModule],
  exports: [MapComponent],
})
export class MapComponentModule {}
