import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';



@Component({
  selector: 'ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent{
  @Input() label = '';
  @Input() color: 'primary' | 'secondary' | 'warn' = 'primary';
  @Input() appearance: 'stroked' | 'filled' ='filled';
  @Input() disabled = false;
  @Input() icon = '';
  @Input() isAction = false;
  @Input() isLoading = false;
  @Input() clickRouterLink?: string[];
  @Input() wrappingText=true;
  @Output() clicked = new EventEmitter();
}

@NgModule({
  declarations: [ButtonComponent],
  imports: [MatButtonModule, MatIconModule, MatProgressSpinnerModule, CommonModule, RouterModule],
  exports: [ButtonComponent],
})
export class UiButtonComponentModule {}
