<p class="text-xs" [ngClass]="{'text-error': this.control.touched && !this.control.valid}">{{ label }}</p>
<div class="flex gap-3 mt-4" [ngClass]="flowDirection === 'row' ? 'flex-row' : 'flex-col'">
  <mat-checkbox
    [class.pointer-events-none]="readonly"
    *ngFor="let item of items"
    [name]="item.name"
    [checked]="currentValue[item.name]"
    [color]="color"
    (change)="onChange(item.name, $event.checked)"
  >{{ isArabic ? item.textArabic : item.text }}</mat-checkbox>
</div>
