import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Showcase, ShowcaseDetails, ShowcaseList, ShowcaseModel } from '../models';
import { StrapiApiService } from '../utils';
import { SharedService } from './shared.service';


@Injectable({
  providedIn: 'root',
})
export class ShowcaseApiService {
  constructor(
    private httpClient: HttpClient,
    private strapiApiService: StrapiApiService,
    private sharedService: SharedService
  ) {}

  getListForTable() {
    return this.strapiApiService.createGetRequest<Showcase>('/shop-list');
  }

  getShowcases() {
    return this.httpClient.get<ShowcaseList>('/shop-list');
  }

  getShowcase(id: number) {
    return this.httpClient.get<ShowcaseDetails>(`/shops/${id}`);
  }

  createShowcase(model: ShowcaseModel) {
    return this.httpClient.post<Showcase>(`/shops`, model);
  }

  updateShowcase(model: ShowcaseModel, id: number) {
    return this.httpClient.put<Showcase>(`/shops/${id}`, model);
  }

  deleteShowcases(ids: number[]) {
    return this.sharedService.delete('/delete-shops', ids);
  }

  export() {
    return this.httpClient.get<{ link: string }>(`/showcases-export`);
  }
}
