<div [formGroup]='formGroup' class='flex flex-col justify-center items-center h-full'>
    <ui-field
        [label]="'New Password'"
        [control]="$any(formGroup.controls.password)"
        [inputType]="'password'"
        class="w-80"
    ></ui-field>
    <ul class='list-disc px-8 pb-4'>
      <li>Password should be at least 8 characters long</li>
      <li>Password should include both numbers and letters</li>
    </ul>
    <ui-button
        [label]="'Reset Password'"
        class="h-11 w-36 float-right"
        (click)='resetPassword()'
        [isLoading]="loading"
        [disabled]='!formGroup.valid'
    ></ui-button>
</div>
