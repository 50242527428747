import { CommonModule } from '@angular/common';
import { Component, NgModule, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';

export interface RadioItem {
  text: string;
  value: any;
  textArabic?: string;
}

@Component({
  selector: 'ui-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent {
  @Input() control = new FormControl('');
  @Input() items: RadioItem[] = [];
  @Input() label?: string;
  @Input() color: ThemePalette = 'primary';
  @Input() flowDirection: 'row' | 'column' = 'column';
  @Input() readonly = false;

  isArabic = false;

  switchLanguage(arabic: boolean) {
    this.isArabic = arabic;
  }
}

@NgModule({
  declarations: [RadioComponent],
  imports: [CommonModule, ReactiveFormsModule, MatRadioModule],
  exports: [RadioComponent],
})
export class RadioComponentModule {}
