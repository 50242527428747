import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UploadedFile, UploadedZipFile } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UploadApiService {
  constructor(private httpClient: HttpClient) {}

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('files', file);
    return this.httpClient.post<UploadedFile[]>('/upload', formData);
  }

  uploadZipFile(file: File) {
    const formData = new FormData();
    formData.append('files', file);
    return this.httpClient.post<UploadedZipFile>('/upload', formData);
  }
}
