import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationList, Notification, NotificationUnreadCount } from '../../../models';
import { StrapiApiService } from '../../../utils';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService {
  constructor(private http: HttpClient, private strapiApi: StrapiApiService) {}

  getListForTable() {
    return this.strapiApi.createGetRequest<Notification>('/user-notifications?_sort=created_at:desc');
  }

  getNotification(limit: number) {
    return this.http.get<NotificationList>(`/user-notifications?_sort=created_at:desc&_limit=${limit}`);
  }

  getUnreadNotificationCount(): Observable<NotificationUnreadCount> {
    return this.http.get<NotificationUnreadCount>('/unread-notifications-count');
  }
}
