import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({
  name: 'abs'
})
export class AbsPipe implements PipeTransform {

  transform(value: number): number {
    return Math.abs(value);
  }

}

@NgModule({
  declarations: [AbsPipe],
  imports: [],
  exports: [AbsPipe]
})
export class AbsPipeModule {
}
