import { CommonModule } from '@angular/common';
import { Component, NgModule, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiModule } from '@smiths/ui';

export interface MapDialogData {
  lat?: number;
  lng?: number;
  center: google.maps.LatLngLiteral;
}

export interface MapDialogResult {
  lat: number;
  lng: number;
  address: string;
}

@Component({
  selector: 'feature-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss'],
})
export class MapDialogComponent implements AfterViewInit {
  @ViewChild('search')
  searchElementRef?: ElementRef;
  @ViewChild(GoogleMap) map?: GoogleMap;
  markers: google.maps.Marker[] = [];
  options: google.maps.MapOptions = {
    zoomControl: false,
    mapTypeControl: true,
    streetViewControl: false,
    fullscreenControl: false,
  };

  constructor(
    private dialogRef: MatDialogRef<MapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MapDialogData
  ) {
    if (this.data.lat && this.data.lng) {
      console.log(this.data.lat, this.data.lng);
      this.ResetMarkerOnMap(new google.maps.LatLng(this.data.lat, this.data.lng));
    }
  }

  ngAfterViewInit(): void {
    const options = {
      componentRestrictions: { country: 'kw' },
    };
    const searchBox = new google.maps.places.Autocomplete(this.searchElementRef?.nativeElement, options);
    this.map?.controls[google.maps.ControlPosition.TOP_CENTER].push(this.searchElementRef?.nativeElement);
    searchBox.addListener('place_changed', () => {
      const place = searchBox.getPlace();
      if (!place) {
        return;
      }
      const bounds = new google.maps.LatLngBounds();
      if (!place.geometry || !place.geometry.location) {
        return;
      }
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
      this.ResetMarkerOnMap(place.geometry.location);
      this.map?.fitBounds(bounds);
    });
  }

  private ResetMarkerOnMap(place: google.maps.LatLng) {
    const marker = new google.maps.Marker({
      position: place,
      map: this.map?.googleMap,
    });
    this.markers.map((marker) => marker.setMap(null));
    this.markers = [];
    this.markers.push(marker);
  }

  click(event: google.maps.MapMouseEvent) {
    this.ResetMarkerOnMap(
      new google.maps.LatLng(event.latLng?.lat() as number, event.latLng?.lng() as number)
    );
  }

  onDone() {
    if (this.markers[0]?.getPosition()?.lat() && this.markers[0]?.getPosition()?.lng()) {
      this.dialogRef.close({
        lat: this.markers[0]?.getPosition()?.lat(),
        lng: this.markers[0]?.getPosition()?.lng(),
        address: this.searchElementRef?.nativeElement.value,
      });
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}

@NgModule({
  declarations: [MapDialogComponent],
  imports: [CommonModule, UiModule, GoogleMapsModule],
  exports: [MapDialogComponent],
})
export class MapDialogComponentModule {}
