import { Pipe, PipeTransform, NgModule } from '@angular/core';
import * as dateFns from 'date-fns';

@Pipe({
  name: 'dateFns'
})
export class DateFnsPipe implements PipeTransform {

  transform(value: string, format: string): string {
    return dateFns.format(new Date(value), format);
  }

}

@NgModule({
  declarations: [DateFnsPipe],
  imports: [],
  exports: [DateFnsPipe],
  providers: [DateFnsPipe]
})
export class DateFnsPipeModule {
}
