<div class="flex gap-4 items-center">
  <p class="lexend-deca text-2xl text-gray-800">{{ data.title }}</p>
  <mat-icon
    [svgIcon]="data.icon ?? 'success'"
    class="w-6 h-6 {{ data.iconClass ?? 'text-green' }}"
  ></mat-icon>
</div>
<div class="h-4 mt-4"><!-- Spacer --></div>
<ui-button
  class="w-40 mt-4"
  [label]="data.buttonText ?? 'Done'"
  color="primary"
  (click)="closeDialog()"
></ui-button>
