import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  FieldComponentModule,
  LoaderComponentModule,
  UiButtonComponentModule,
  PhoneInputComponentModule,
  InlineCalenderComponentModule,
  SelectComponentModule,
  PaginationComponentModule,
  RadioComponentModule,
  NavigationBarComponentModule,
  TabsComponentModule,
  DynamicComponentModule,
  ToggleComponentModule,
  TableListComponentModule,
  CheckboxGroupComponentModule,
  Image360ViewerComponentModule,
} from './components';
import {
  ForNumberDirectiveModule,
  ImageLoaderDirectiveModule,
  StepExtendDirectiveModule,
  StepperExtendDirectiveModule
} from './directives';
import { AbsPipeModule, DateFnsPipeModule } from './pipes';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    MatIconModule,
    FieldComponentModule,
    PhoneInputComponentModule,
    UiButtonComponentModule,
    LoaderComponentModule,
    InlineCalenderComponentModule,
    SelectComponentModule,
    PaginationComponentModule,
    RadioComponentModule,
    NavigationBarComponentModule,
    TabsComponentModule,
    DynamicComponentModule,
    ToggleComponentModule,
    TableListComponentModule,
    ForNumberDirectiveModule,
    StepperExtendDirectiveModule,
    DateFnsPipeModule,
    CheckboxGroupComponentModule,
    StepExtendDirectiveModule,
    Image360ViewerComponentModule,
    AbsPipeModule,
    ImageLoaderDirectiveModule
  ],
})
export class UiModule {
  icons = [
    'close',
    'check',
    'check-circle',
    'close-circle',
    'question-circle',
    'notification',
    'profile',
    'profile-fill',
    'dashboard',
    'users',
    'showcase',
    'vault',
    'configuration',
    'affiliate',
    'sidebar-toggle',
    'field-handle',
    'kuwait',
    'united-kingdom',
    'username',
    'search',
    'field-handle',
    'eye-hide',
    'eye-show',
    'info-circle',
    'ban',
    'arrow-back',
    'export',
    'add-square',
    'table-edit',
    'table-delete',
    'arrow-down',
    'diamond',
    'images',
    'arrow-right',
    'arrow-left',
    'arrow-bottom',
    'arrow-top',
    'arrow-forward',
    'success',
    'location',
    'map-location',
    'setting',
    'chevron-right',
    'request-title',
    'request-category',
    'request-initial-price',
    'request-last-estimated',
    'kwd',
    'location',
    'map-location',
    'setting',
    'chevron-right',
    'price-history',
    'address',
    'email',
    'phone',
    'language-toggle-handle',
    'arrow-up-rounded',
    'arrow-down-rounded',
    'pink-bullet',
    'blue-bullet',
    'grey-bullet',
    'no-showcase',
  ];

  constructor(private sanitizer: DomSanitizer, private iconRegistry: MatIconRegistry) {
    for (const icon of this.icons) {
      iconRegistry.addSvgIcon(
        icon,
        sanitizer.bypassSecurityTrustResourceUrl(`./assets/ui/icons/${icon}.svg`)
      );
    }
  }
}
