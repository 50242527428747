import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import {
  AUTH_STATE_TOKEN,
  AuthState,
  NOTIFICATION_STATE_TOKEN,
  NotificationState,
} from '@smiths/data-access';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeatureModule } from '@smiths/feature';
import { UiModule } from '@smiths/ui';
import { ApiInterceptor, NGXSStorageEngine, TokenInterceptor, ErrorInterceptor } from '@smiths/utility';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxsSelectSnapshotModule.forRoot(),

    NgxsModule.forRoot([AuthState, NotificationState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: [AUTH_STATE_TOKEN, NOTIFICATION_STATE_TOKEN],
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    FeatureModule,
    UiModule,
  ],
  providers: [
    {
      provide: STORAGE_ENGINE,
      useClass: NGXSStorageEngine,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: 'apiBaseUrl',
      useValue: environment.apiBaseUrl,
    },
    {
      provide: 'googleMapApikey',
      useValue: environment.googleMapAPIKey,
    },
    {
      provide: 'passwordPattern',
      useValue: environment.passwordPattern,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
