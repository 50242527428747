export interface AuthData {
    jwt: string;
    user: User;
    rememberMe?: boolean;
}

export interface UserList {
  list: User[];
}

export interface User {
  id:                 number;
  username:           string;
  email:              string;
  provider:           string;
  resetPasswordToken: null | string;
  confirmationToken:  null | string;
  confirmed:          null | boolean;
  blocked:            boolean;
  role:               Role;
  created_by:         AtedBy;
  updated_by:         AtedBy;
  created_at:         string;
  updated_at:         string;
  phone_number:       null |  string;
  country_code:       null |  string;
  verification_code:  null |  string;
  phone_verified:     null | boolean;
  email_verified:     null | boolean;
  deleted_at:         null | string;
  deleted_by:         null |  AtedBy;
  deleted_fields:     null | string[];
  requests_count:     number;
  apple_id:           null | string;
  first_name:         null | string;
  last_name:          null | string;
  gender:             null | string;
  avatar:             null | string;
  devices:            Device[];
  requests:           any[];
  bookmarks:          any[];
}

export interface AtedBy {
  id:                 number;
  firstname:          string;
  lastname:           string;
  username:           null | string;
  email:              string;
  password:           string;
  resetPasswordToken: null | string;
  registrationToken:  null | string;
  isActive:           boolean;
  blocked:            null | boolean;
  preferedLanguage:   null | string;
}

export interface Device {
  id:           number;
  uuid:         string;
  fcm:          string;
  user:         number;
  published_at: string;
  created_by:   null | AtedBy;
  updated_by:   null | AtedBy;
  created_at:   string;
  updated_at:   string;
}

export interface Role {
  id:          number;
  name:        string;
  description: string;
  type:        string;
  created_by:  null | AtedBy;
  updated_by:  null | AtedBy;
}
