import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardStatistics } from '../models/dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService {
  constructor(private http: HttpClient) {}
  getDashboard() {
    return this.http.get<DashboardStatistics>(`/dashboard`);
  }
}
