import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, StateToken } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { NotificationApiService } from '../backend/notification-api.service';
import { NotificationUnreadCountUpdateAction } from './notification.actions';

export interface NotificationStateModel {
  unreadCount?: number;
}

export const NOTIFICATION_STATE_TOKEN = new StateToken<NotificationStateModel>('notification');

@State<NotificationStateModel>({
  name: NOTIFICATION_STATE_TOKEN,
  defaults: {},
})
@Injectable()
export class NotificationState {
  constructor(private notificationApiService: NotificationApiService) {}

  @Selector([NOTIFICATION_STATE_TOKEN])
  static getState(state: NotificationStateModel) {
    return state;
  }

  @Action(NotificationUnreadCountUpdateAction)
  getUnreadCount(ctx: StateContext<NotificationStateModel>) {
    return this.notificationApiService.getUnreadNotificationCount().pipe(
      tap((result) => {
        ctx.patchState({
          unreadCount: result.unread_count,
        });
      })
    );
  }
}
