export interface UploadedFile {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: Formats;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string;
  provider: string;
  provider_metadata: any;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
}

export interface UploadedZipFile {
  id: number;
  name: string;
  alternativeText: any;
  caption: any;
  width: any;
  height: any;
  formats: any;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string;
  provider: string;
  provider_metadata: any;
  created_at: string;
  updated_at: string;
  related: any[];
}

interface Formats {
  thumbnail: FormatDetails;
  large: FormatDetails;
  medium: FormatDetails;
  small: FormatDetails;
}

interface FormatDetails {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  width: number;
  height: number;
  size: number;
  path: string;
  url: string;
}
