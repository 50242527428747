import { Component, OnInit, NgModule, Inject, ElementRef, Renderer2 } from '@angular/core';
import { UiModule } from '@smiths/ui';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

export interface MessageDialogData {
  title: string;
  icon?: string;
  iconClass?: string;
  buttonText?: string;
  // compact?: boolean;
}

@Component({
  selector: 'feature-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<MessageDialogComponent, never>,
    @Inject(MAT_DIALOG_DATA) public data: MessageDialogData,
    private host: ElementRef,
    private renderer2: Renderer2
  ) {
    // this.renderer2.setStyle(this.host.nativeElement, 'width', this.data.compact ? '27vw' : '47vw');
  }
  ngOnInit(): void {
  }
  closeDialog() {
    this.dialogRef.close();
  }
}

@NgModule({
  declarations: [MessageDialogComponent],
  imports: [CommonModule, UiModule],
  exports: [MessageDialogComponent]
})
export class MessageDialogComponentModule {
}
