<p class="text-xs mb-1" [ngClass]="{ 'text-error': this.control?.touched && !this.control?.valid }">
  {{ label }}
</p>
<mat-radio-group
  [formControl]="control"
  [color]="color"
  class="ui-radio"
  [ngClass]="{ 'flex flex-row': flowDirection === 'row', 'flex flex-col': flowDirection === 'column' }"
>
  <mat-radio-button
    [class.pointer-events-none]="readonly"
    class="{{ flowDirection === 'row' ? 'mr-2 my-1' : 'my-1 mr-2' }}"
    *ngFor="let item of items"
    [value]="item.value"
    >{{ isArabic ? item.textArabic : item.text }}
  </mat-radio-button>
</mat-radio-group>
