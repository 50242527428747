import { DomSanitizer } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { ImageCropperModule, ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, NgModule, Inject } from '@angular/core';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UiButtonComponentModule } from '@smiths/ui';

export interface ImageCropperDialogData {
  image?: File;
}

export type ImageCropperDialogResult = { result: boolean; image: File | undefined };

@Component({
  selector: 'feature-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
})
export class ImageCropperDialogComponent {
  imageHaveCropped?: File;
  showCroppedImage: any;
  constructor(
    private dialogRef: MatDialogRef<ImageCropperDialogComponent, ImageCropperDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: ImageCropperDialogData
  ) {}

  closeDialog(result: ImageCropperDialogResult) {
    console.log(result);
    this.dialogRef.close(result);
  }

  async imageCropped(image: ImageCroppedEvent ) {
    this.imageHaveCropped = await this.base64ToFile(image.base64 as string);
    this.showCroppedImage = image.base64;
  }

  async base64ToFile(base64Image: string): Promise<File> {
    const url = base64Image;
   const res = await fetch(url);
    const blob = await res.blob();
    const file = new File([blob], this.data.image?.name ? this.data.image.name : '', {
      type: 'image/png',
    });
    return file;
  }
}

@NgModule({
  declarations: [ImageCropperDialogComponent],
  imports: [UiButtonComponentModule, CommonModule, MatIconModule, NgxFileDropModule, ImageCropperModule],
  exports: [ImageCropperDialogComponent],
})
export class ImageCropperDialogComponentModule {}
