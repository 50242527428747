import { CommonModule } from '@angular/common';
import { Component, NgModule, ContentChildren, QueryList, AfterContentInit, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TabComponent } from './tab/tab.component';

export interface Tab {
  title: string;
  active: boolean;
  fullWidth: boolean;
  disabled: boolean,
}

@Component({
  selector: 'ui-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;
  @Input() title?: string;
  @Output() backClicked = new EventEmitter();
  @Output() selected = new EventEmitter<number>();
  fullWidth = false;

  ngAfterContentInit() {
    if (this.tabs.first) {
      this.onSelectTab(this.tabs.first);
    }
  }

  onSelectTab(tab: Tab) {
    this.tabs.toArray().forEach((tab) => (tab.active = false));
    tab.active = true;
    this.fullWidth = tab.fullWidth;
    this.selected.emit(this.tabs.toArray().findIndex(x => x === tab));
  }

  onClickBack() {
    this.backClicked.emit();
  }
}

@NgModule({
  declarations: [TabComponent, TabsComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule],
  exports: [TabComponent, TabsComponent],
})
export class TabsComponentModule {}
