import { Component, OnInit, NgModule, Input } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'ui-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
  @Input() control = new FormControl('en');
  isArabic = false;

  constructor() { }

  ngOnInit(): void {
  }
  onClick(lang: 'en' | 'ar') {
    this.control?.setValue(lang);
    this.isArabic = lang === 'ar';
  }
}

@NgModule({
  declarations: [ToggleComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  exports: [ToggleComponent]
})
export class ToggleComponentModule {
}
