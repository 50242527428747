import { Component, NgModule } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@smiths/data-access';
import { UiModule } from '@smiths/ui';

@Component({
  selector: 'smiths-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  formGroup: FormGroup;
  loading = false;

  constructor(private authService: AuthService,private router: Router) {
    this.formGroup = new FormGroup({
      email: new FormControl('',Validators.compose([Validators.required,Validators.email])),
    })
  }



  resetPassword(){
    if(this.formGroup.valid){

    this.loading = true;
    const val = this.formGroup.value;
    return this.authService.sendResetLink(val.email).subscribe(
      () => {
        this.loading = false;
        this.router.navigate(['auth','link-sent'])
      })
    } else return  
}
}

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [UiModule],
  exports: [ForgotPasswordComponent]
})
export class ForgotPasswordComponentModule {
}
