<p class="lexend-deca font-normal text-gray-800 text-xl">Admin Profile</p>
<div class="flex flex-row mt-5">
  <feature-photo-uploader
    [control]="$any(personalFormGroup?.controls?.avatar)"
    [addText]="'Upload new avatar'"
    [deleteText]="'Delete avatar'"
    [editText]="'Upload new avatar'"
    [deleteDialogTitle]="'Delete avatar?'"
    [deleteDialogMessage]="'Are you sure that you want to delete this avatar?'"
    (uploaded)="updateProfile()"
    (deleted)="updateProfile()"
  ></feature-photo-uploader>
</div>
<div class="mt-10 flex flex-col">
  <ui-field
    class="w-full"
    label="Email"
    placeholder="Email@gmail.com"
    [control]="$any(personalFormGroup?.controls?.email)"
  ></ui-field>
  <ui-field
    class="w-full mt-5 button-input"
    label="Password"
    placeholder="RESET PASSWORD"
    (click)="openChangePassword()"
    [control]="$any(personalFormGroup?.controls?.resetPassword)"
  ></ui-field>
</div>
<div class="right-0 mt-10" dir="rtl">
  <ui-button [label]="'Log out'" appearance="stroked" (click)="logout()"></ui-button>
</div>
