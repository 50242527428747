import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Selector, State, StateToken, Action, StateContext } from '@ngxs/store';
import { from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthData } from '../../../models/auth.model';
import { AuthApiService } from '../backend/auth-api.service';
import { ClearStorageAction, LoginAction, LogoutAction, SaveAuthDataAction, UpdateProfileAvatarAction } from './auth.action';

export interface AuthStateModel {
  user?: any;
  rememberMe?: boolean
  jwt?: string;
}

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth')

@State({
  name: AUTH_STATE_TOKEN,
  defaults: {}
})
@Injectable({
  providedIn: 'root'
})
export class AuthState {
  constructor(
    private authApiService: AuthApiService,
    private router: Router,
    private ngZone: NgZone,
  ) {}

  @Selector([AUTH_STATE_TOKEN])
  static getState(state: AuthStateModel) {
    return state;
  }
  @Selector()
  static isAuthenticated(state: AuthData): boolean {
    return !!state?.jwt;
  }
  @Selector()
  static rememberMe(state: AuthStateModel): boolean {
    return !!state?.rememberMe;
  }
  @Action(LoginAction)
  login({ patchState }: StateContext<AuthData>, { username, password, rememberMe, fcmToken }: LoginAction) {
    return from(
      this.authApiService.login(username, password, fcmToken).pipe(
        tap((result: AuthData) => {
          patchState({
            jwt: result?.jwt,
            user: result?.user,
            rememberMe: rememberMe,
          });

          this.ngZone.run(() => this.router.navigate(['dashboard']));
        })
      )
    );
  }

  @Action(LogoutAction)
  logout({ setState, dispatch }: StateContext<AuthData | undefined>) {
    setState(undefined);
    dispatch(new ClearStorageAction());
    this.ngZone.run(() => this.router.navigate(['auth', 'login']));
  }

  @Action(ClearStorageAction)
  clearStorage() {
    sessionStorage.clear();
    localStorage.clear();
  }

  @Action(SaveAuthDataAction)
  saveToken({ patchState }: StateContext<AuthData | undefined>, data: AuthData) {
    patchState({
      jwt: data?.jwt,
      user: data?.user,
    });
  }

  @Action(UpdateProfileAvatarAction)
  updateProfile({ patchState }: StateContext<AuthData | undefined>, data: {data: number}) {
    return from(
      this.authApiService.updateProfileAvatar(data.data).pipe(
        tap((result) => {
          patchState({
            user: result,
          });
        })
      )
    );
  }
}
