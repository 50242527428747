<div #Container class="feature-imagebox">
  <div
    *ngFor="let _ of quantityArray; index as index"
    class="w-inherit h-full relative flex-none transition-all gap-2 flex flex-col items-center justify-center"
    [ngStyle]="{ 'margin-left': index === 0 ? ((wrapperWidth) * -1 * selectedIndex) + 'px' : 0 }"
  >
    <ng-container *ngIf="control.value[index]; else NoFile">
      <img class="w-full" [src]="control.value[index].src" alt="" />
      <mat-icon
        *ngIf="!readonly"
        (click)="onRemoveImage(index)"
        class="absolute top-3 right-3 w-4 h-4 text-gray-600 shadow-sm cursor-pointer"
        svgIcon="close"
      ></mat-icon>
    </ng-container>
    <ng-template #Uploading>
      <div class="absolute flex items-center justify-center opacity-50 bg-black">
        <mat-spinner [strokeWidth]="2" diameter="90" class="absolute left-0"></mat-spinner>
      </div>
    </ng-template>
    <ng-template #NoFile>
      <ngx-file-drop
        [disabled]="readonly"
        (onFileDrop)="onSelectImage($event)"
        [multiple]="false"
        accept=".png,.jpg"
        class="w-full h-full"
      >
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector" >
          <div (click)="!readonly ? openFileSelector() : null" class="w-full h-full flex items-center justify-center flex-col cursor-pointer px-16 text-center text-gray-600">
            <p *ngIf="!readonly">Click to select files or drag and drop.</p>
          </div>
        </ng-template>
      </ngx-file-drop>
    </ng-template>
  </div>
  <mat-icon
    [class.opacity-40]="quantityArray.length <= 1"
    (click)="onPrevious()"
    svgIcon="arrow-back"
    class="absolute left-3 cursor-pointer w-3 text-gray-600 shadow-sm"
  ></mat-icon>
  <mat-icon
    [class.opacity-40]="quantityArray.length <= 1"
    (click)="onNext()"
    svgIcon="arrow-forward"
    class="absolute right-3 cursor-pointer w-3 text-gray-600 shadow-sm ml-auto"
  ></mat-icon>
  <div class="flex gap-1.5 absolute bottom-1 w-full items-center justify-center h-4">
    <div *forNumber="quantityArray.length; let index" [class.active]="selectedIndex === index" class="pagination-dot"></div>
  </div>
</div>
