import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Settings } from '../models/setting.model';

@Injectable({
  providedIn: 'root',
})
export class SettingApiService {
  constructor(private http: HttpClient) {}

  getSettings(): Observable<Settings> {
    return this.http.get<Settings>('/settings');
  }

  updateSettings(model: Partial<Settings>): Observable<Settings> {
    return this.http.put<Settings>(`/settings`, model);
  }
}
