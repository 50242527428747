<p class="lexend-deca text-xl mt-3">{{ title }} stepper</p>
<mat-vertical-stepper
  #Stepper
  uiStepperExtend
  (selectionChange)="onStepChange($event.selectedIndex)"
  activeStepClass="flex-auto"
  class="vault-stepper pr-3 mt-6 h-full flex flex-col relative overflow-auto mr-2"
>
  <mat-step
    uiStepExtend
    [useCustomImage]="true"
    [image]="step.stepImage ? this.apiBaseUrl + step.stepImage.url : null"
    *ngFor="let step of currentSteps; index as index"
    [label]="arabic ? (step.stepNameArabic ?? 'New Step') : (step.stepName ?? 'New Step')"
  >
    <div class="text-gray-800 font-medium {{ question.className }}" *ngFor="let question of step.questions; index as questionIndex">
      <p class="mb-2">
        {{ arabic ? question.questionArabic : question.question }} {{ question.required ? '*' : '' }}
        <mat-icon
          *ngIf="state !== 'view' && !question.readonly"
          (click)="onQuestionEditClick(index, questionIndex, question)"
          svgIcon="table-edit"
          class="text-gray-500 cursor-pointer align-text-bottom ml-1"
        ></mat-icon>
        <mat-icon
          *ngIf="state === 'create' && !question.readonly"
          (click)="onQuestionRemoveClick(index, questionIndex)"
          svgIcon="table-delete"
          class="text-gray-500 cursor-pointer align-text-bottom ml-3"
        ></mat-icon>
      </p>
      <ui-dynamic [component]="question.component!" [context]="$any(question.options)"></ui-dynamic>
    </div>
  </mat-step>
</mat-vertical-stepper>
<div class="w-full">
  <button *ngIf="state !== 'view'" [disabled]="state === 'edit'" (click)="onAddClick()" mat-button class="mat-button-custom add-step-button w-1/2">
    <div class="flex gap-3 items-center">
      <div
        class="
          border border-gray-300
          rounded-full
          w-10
          h-10
          flex
          items-center
          justify-center
          text-primary-dark
          bg-white
          text-xl
          flex-none
        "
      >
        +
      </div>
      <p class="text-sm text-primary-dark font-medium">Add new step</p>
    </div>
  </button>
</div>
<div class="absolute inset-0 left-5 pl-0.5 pointer-events-none top-20" [ngClass]="state === 'view' ? 'bottom-0' : 'bottom-0'">
  <div class="h-full border-l-4 border-dashed border-gray-100"></div>
</div>
