<ui-navigation-bar text="Choose location" (back)="onClose()"></ui-navigation-bar>
<div class="w-11/12 mx-auto">
  <div class="map-wrapper my-4">
    <ng-template [ngTemplateOutlet]="searchForm"></ng-template>
    <google-map
      (mapClick)="click($event)"
      height="60vh"
      width="100%"
      [options]="options"
      [center]="data.center"
    >
      <map-marker
        *ngIf="markers[0]"
        [position]="$any({ lat: markers[0]?.getPosition()?.lat(), lng: markers[0]?.getPosition()?.lng() })"
      ></map-marker>
    </google-map>
  </div>
  <div class="flex justify-end">
    <ui-button
      (click)="onDone()"
      [disabled]="$any(!markers[0]?.getPosition()?.lat() || !markers[0]?.getPosition()?.lng())"
      color="primary"
      label="Done"
    ></ui-button>
  </div>
</div>

<ng-template #searchForm>
  <input
    #search
    type="text"
    placeholder="Search Google Maps"
    class="
      px-3
      py-3
      placeholder-gray-caption
      relative
      bg-white
      rounded
      text-sm
      border border-gray-divider
      outline-none
      focus:outline-none
      w-full
    "
  />
</ng-template>
