import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { NotificationUnreadCountUpdateAction } from './state/notification.actions';
import { NOTIFICATION_STATE_TOKEN } from './state/notification.state';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private store: Store) {}

  get state() {
    return this.store.selectSnapshot(NOTIFICATION_STATE_TOKEN);
  }
  get state$() {
    return this.store.select(NOTIFICATION_STATE_TOKEN);
  }

  updateUnreadCount() {
    return this.store.dispatch(new NotificationUnreadCountUpdateAction());
  }
}
