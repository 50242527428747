<div class="flex flex-row ml-5 mt-2 mb-4 select-none">
  <div (click)="back.emit()" class="inline-flex cursor-pointer" [routerLink]="backRouterLink">
    <div>
      <mat-icon class="w-3 h-6 text-primary-800" [svgIcon]="'arrow-back'"></mat-icon>
    </div>
    <div class="flex flex-col ml-8">
      <p class="font-medium text-xl text-black roboto -mt-1">{{ text }}</p>
      <p class="font-medium text-xs text-black roboto mt-1">{{ caption }}</p>
    </div>
  </div>
</div>
