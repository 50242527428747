/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { Component, OnInit, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { VaultRequest } from '@smiths/data-access';

@Component({
  selector: 'ui-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit {

  vaultRequest?: VaultRequest;
  @Input() text = 'Go Back';
  @Input() caption?: string;
  @Input() backRouterLink: string[] | undefined;
  @Output() back = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  declarations: [NavigationBarComponent],
  imports: [MatIconModule, RouterModule],
  exports: [NavigationBarComponent],
})
export class NavigationBarComponentModule {}
