import { NotificationService } from '@smiths/data-access';
import { DialogService, ProfileComponent } from '@smiths/feature';
import { Component, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiModule } from '@smiths/ui';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatListModule } from '@angular/material/list';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { map, Observable } from 'rxjs';

interface Link {
  title: string;
  icon?: string;
  url: string;
  children?: Link[];
}

@Component({
  selector: 'smiths-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  animations: [
    trigger('sidebarToggle', [
      state(
        'opened',
        style({
          width: '168px',
        })
      ),
      state(
        'closed',
        style({
          width: '0px',
        })
      ),
      transition('* => closed', [animate('0.3s ease-out')]),
      transition('* => opened', [animate('0.3s ease-out')]),
    ]),
    trigger('contentToggle', [
      state(
        'opened',
        style({
          marginLeft: '288px',
        })
      ),
      state(
        'closed',
        style({
          marginLeft: '120px',
        })
      ),
      transition('* => closed', [animate('0.3s ease-out')]),
      transition('* => opened', [animate('0.3s ease-out')]),
    ]),
  ],
})
export class AppLayoutComponent {
  links: Link[] = [
    {
      title: 'Dashboard',
      icon: 'dashboard',
      url: 'dashboard',
    },
    {
      title: 'Users',
      icon: 'users',
      url: 'users',
    },
    {
      title: 'Vault',
      icon: 'vault',
      url: 'vault',
      children: [
        {
          title: 'All',
          url: 'all',
        },
        {
          title: 'Requests',
          url: 'requests',
        },
      ],
    },
    {
      title: 'Show case',
      icon: 'showcase',
      url: 'showcase',
      children: [
        {
          title: 'Show case',
          url: 'all',
        },
        {
          title: 'Banners',
          url: 'banners',
        },
      ],
    },
    {
      title: 'Affiliate',
      icon: 'affiliate',
      url: 'affiliates',
    },
  ];
  isDesktop: boolean;
  currentLink?: Link;
  isSecondSidebarOpen = false;
  unreadCount$?: Observable<number | undefined>;
  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private notificationService: NotificationService,
    private dialog: DialogService
  ) {
    this.notificationService.updateUnreadCount().subscribe();
    this.isDesktop = this.deviceDetectorService.isDesktop();
    this.unreadCount$ = this.notificationService.state$.pipe(map(x => x?.unreadCount))
  }

  onMouseEnter(link: Link) {
    this.currentLink = this.links.find((x) => x.url === link.url);
    if (this.currentLink?.children) {
      this.isSecondSidebarOpen = this.currentLink?.children?.length > 0;
    } else {
      this.isSecondSidebarOpen = false;
    }
  }

  onMouseLeave() {
    this.currentLink = undefined;
    this.isSecondSidebarOpen = false;
  }

  onNotificationClick() {
    this.dialog.notification();
  }
  openProfile() {
    this.dialog.open(ProfileComponent, {
      width: '375px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      position: {
        top: '116px',
        right: '16px',
      },
    });
  }
}

@NgModule({
  declarations: [AppLayoutComponent],
  imports: [CommonModule, RouterModule, UiModule, MatSidenavModule, MatListModule, RouterModule.forChild([])],
  exports: [AppLayoutComponent],
})
export class AppLayoutComponentModule {}
