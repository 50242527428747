import { Timescale } from './showcase.model';
import { Vault, VaultRequest } from './vault.model';
import { UploadedFile } from './uploaded-file.model';

export interface DashboardStatistics {
  male_users_percentage: number;
  female_users_percentage: number;
  unclear_users_percentage: number;
  total_users: number;
  disabled_users: number;
  active_users: number;
  total_users_per_month: TotalUsersPerMonth[];
  current_show_case: CurrentShowCase;
  top_vaults: Vault[];
  new_requests: VaultRequest[];
  total_requests: number;
}

export interface CurrentShowCase {
  id: number;
  published_at: string;
  created_by: null;
  updated_by: null;
  created_at: string;
  updated_at: string;
  en_name: string;
  ar_name: string;
  phone: string;
  email: string;
  instagram: string;
  latitude: number;
  longitude: number;
  image_360: { images: UploadedFile[] };
  status: string;
  address: null;
  normal_images: UploadedFile[];
  timescales: Timescale[];
  logo:UploadedFile;
}

export interface TotalUsersPerMonth {
  id: number;
  year: number;
  month: number;
  count: number;
  created_by: null;
  updated_by: null;
  created_at: string;
  updated_at: string;
}
