<mat-form-field
  *ngIf="!mini; else MiniFormField"
  [class.is-compact]="compact"
  class="ui-field relative w-full"
  appearance="outline"
  floatLabel="always"
  [class.pt-6]="!!arabicControl"
  [class.invalid-chips]="multipleDateRanges && isDateRangeChipsInvalid()"
>
  <p class="field-label" *ngIf="!compact && label">{{ label }}</p>
  <mat-label *ngIf="compact && label"> {{ label }}</mat-label>
  <div *ngIf="arabicControl" class="field-language">
    <div (click)="selectEnglish()" [class.active]="!arabicSelected">
      <mat-icon svgIcon="field-handle"></mat-icon>
      <p>Eng</p>
    </div>
    <div (click)="selectArabic()" class="left-3" [class.active]="arabicSelected">
      <mat-icon svgIcon="field-handle"></mat-icon>
      <p>Arb</p>
    </div>
  </div>
  <mat-icon matPrefix *ngIf="prefixIcon" class="w-6 h-6" [svgIcon]="prefixIcon"></mat-icon>
  <ng-container [ngSwitch]="controlType">
    <input *ngSwitchCase="'input'"
    class="text-black font-medium"
      matInput
      [formControl]="$any(arabicSelected ? arabicControl : control)"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [type]="inputType === 'password' ? showPassword ? 'text' : 'password' : inputType"/>
    <ng-container *ngSwitchCase="'date-range'">
      <mat-chip-list *ngIf="multipleDateRanges && dateRangeChipsControl?.value?.length > 0">
        <drag-scroll [scrollbar-hidden]="true">
          <mat-chip *ngFor="let range of dateRangeChipsControl?.value" selected>
            {{range.start | date:'YYYY/MM/dd'}}-{{range.end | date:'YYYY/MM/dd'}}
            <button matChipRemove>
              <mat-icon (click)="onDeleteRange(range)">cancel</mat-icon>
            </button>
          </mat-chip>
        </drag-scroll>
      </mat-chip-list>
      <mat-date-range-input [rangePicker]="picker" [ngClass]="{'hide-placeholder': multipleDateRanges && dateRangeChipsControl?.value?.length > 0}">
        <input matStartDate [formControl]="control" placeholder="Start date" readonly>
        <input matEndDate [formControl]="endControl!" placeholder="End date" readonly>
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon color="primary">date_range</mat-icon>
      </mat-datepicker-toggle>
      <mat-date-range-picker #picker>
        <mat-date-range-picker-actions>
          <button mat-raised-button color="primary" class="rounded-xl" matDateRangePickerApply (click)="onAddRange()">
            {{ multipleDateRanges && dateRangeChipsControl?.value?.length > 0 ? 'New timescale' : 'Set' }}
          </button>
        </mat-date-range-picker-actions>
      </mat-date-range-picker>
    </ng-container>
  </ng-container>
  <mat-icon matSuffix *ngIf="suffixIcon" class="w-6 h-6" [svgIcon]="suffixIcon"></mat-icon>
  <mat-icon
    matSuffix
    class="w-6 h-6 hover:text-primary-900 transition-all"
    *ngIf="inputType === 'password'"
    (click)="showPassword = !showPassword"
    [svgIcon]="showPassword ? 'eye-hide' : 'eye-show'"
  ></mat-icon>
  <mat-error *ngIf="showError">
    {{ customErrorText ?? currentError }}
  </mat-error>
</mat-form-field>

<ng-template #MiniFormField>
  <div class="ui-field-mini">
    <ng-container [ngSwitch]="controlType">
      <ng-container *ngSwitchCase="'date-range'">
        <mat-form-field class="mini-form-field w-full" (click)="rangePicker.open()" appearance="fill">
          <mat-icon matPrefix class="field-prefix" *ngIf="prefixIcon" [svgIcon]="prefixIcon"></mat-icon>
          <mat-date-range-input [rangePicker]="rangePicker" [ngClass]="{'hide-separator': !control.value}">
            <input matStartDate placeholder="All" class="text-black font-semibold" matInput [formControl]="control" readonly>
            <input matEndDate placeholder="" class="text-black font-semibold" matInput [formControl]="endControl!" readonly>
          </mat-date-range-input>
          <mat-date-range-picker #rangePicker>
            <mat-date-range-picker-actions>
              <button mat-button matDateRangePickerCancel (click)="clearDateRangePicker()" class="picker-btn">Clear</button>
              <button mat-raised-button color="primary" matDateRangePickerApply class="picker-btn">Search</button>
            </mat-date-range-picker-actions>
          </mat-date-range-picker>
          <mat-icon matSuffix (click)="rangePicker.open()" class="field-suffix w-2 h-2 text-gray-700 ml-auto" svgIcon="arrow-down"></mat-icon>
        </mat-form-field>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <mat-icon class="field-prefix" *ngIf="prefixIcon" [svgIcon]="prefixIcon"></mat-icon>
        <input class="text-black font-semibold" matInput [formControl]="control" [placeholder]="placeholder" [type]="inputType">
      </ng-container>
    </ng-container>
  </div>
</ng-template>
