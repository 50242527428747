import {
  Component, ElementRef,
  Injector,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { CompRef, ViewService } from '@ngneat/overview';
import { ComponentType } from '@angular/cdk/overlay';
import { ExcludeFunctions } from '@ngneat/overview/lib/views/types';

@Component({
  selector: 'ui-dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.scss']
})
export class DynamicComponent<T> implements OnInit, OnChanges {
  @Input() component?: ComponentType<T>;
  @Input() injector?: Injector;
  @Input() context?: Partial<ExcludeFunctions<T>>;

  private compRef?: CompRef<T>;

  constructor(private vcr: ViewContainerRef, private viewService: ViewService, private host: ElementRef) {
  }

  ngOnInit() {
    this.vcr.clear();
    this.resolveComponent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.component && !changes.component.isFirstChange()) {
      this.resolveComponent();
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  invokeComponentFunction(fn: { [p in keyof T]: T[p] extends Function ? p : never; }[keyof T], arg?: any): unknown {
    if (this.compRef) {
      const component = this.compRef.ref.instance as any;
      return component[fn](arg);
    }
    return undefined;

  }

  private resolveComponent() {
    if (!this.component) {
      return;
    }
    this.compRef?.destroy();
    this.compRef = this.viewService.createComponent(this.component, {
      vcr: this.vcr,
      injector: this.injector ?? this.vcr.injector,
    });
    this.compRef.setInputs(this.context ?? {})
    this.compRef.appendTo(this.host.nativeElement)
  }
}
@NgModule({
  declarations: [DynamicComponent],
  imports: [],
  exports: [DynamicComponent]
})
export class DynamicComponentModule {}
