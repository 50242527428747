import { Component, NgModule } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '../dialog/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { UiModule } from '@smiths/ui';
import { PhotoUploaderComponentModule } from '../photo-uploader/photo-uploader.component';
import { AuthService } from '@smiths/data-access';
import { take } from 'rxjs/operators';

@Component({
  selector: 'feature-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  personalFormGroup: FormGroup;

  constructor(
    private dialog: DialogService,
    private dialogRef: MatDialogRef<ProfileComponent>,
    private auth: AuthService,
    )
  {
    const profile = this.auth.getAuthSnapShot()?.user;
    this.personalFormGroup = new FormGroup({
      avatar: new FormControl(profile?.avatar, Validators.required),
      email: new FormControl({value: profile?.email, disabled: true}, Validators.email),
      resetPassword: new FormControl({value: "RESET PASSWORD", disabled: true}),
    });
  }

  openChangePassword() {
    this.dialogRef.close();
    this.dialog.changePassword()
  }

  logout() {
    this.dialog
    .prompt({ title: 'Logout', message: 'Are you sure you want to logout?', yesButtonText: 'Logout' })
    .afterClosed()
    .pipe(take(1))
    .subscribe((result) => {
      if (result) {
          this.dialogRef.close();
          this.auth.logout()
        }
    });
  }

  updateProfile() {
    const avatar = this.personalFormGroup.controls.avatar.value?.id;
    this.auth.updateProfile(avatar);
  }
}

@NgModule({
  declarations: [ProfileComponent],
  imports: [UiModule, PhotoUploaderComponentModule],
  exports: [ProfileComponent]
})
export class ProfileComponentModule {
}
