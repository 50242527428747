import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent {
  @Input() title = 'Title';
  @Input() active = false;
  @Input() fullWidth = false;
  @Input() disabled = false;
}
