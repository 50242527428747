import { Component, OnInit, NgModule, EventEmitter, Output } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';

@Component({
  selector: 'ui-inline-calender',
  templateUrl: './inline-calender.component.html',
  styleUrls: ['./inline-calender.component.scss'],
})
export class InlineCalenderComponent implements OnInit {
  selected: Date | null | undefined;
  @Output() dateChanged = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  onDateChange(event: any) {
    this.dateChanged.emit(event);
  }
}

@NgModule({
  declarations: [InlineCalenderComponent],
  imports: [MatDatepickerModule, MatNativeDateModule, MatCardModule],
  exports: [InlineCalenderComponent],
})
export class InlineCalenderComponentModule {}
