import { Injectable, ApplicationRef, Inject } from '@angular/core';
import { interval, concat, filter } from 'rxjs';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { first } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PWAService {
  constructor(appRef: ApplicationRef, public swUpdate: SwUpdate, @Inject(DOCUMENT) private document: Document,) {
    if (swUpdate.isEnabled) {
      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = appRef.isStable.pipe(
        first(isStable => isStable)
      );
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(
        appIsStable$,
        everySixHours$
      );
      everySixHoursOnceAppIsStable$.subscribe(() => swUpdate.checkForUpdate());
    }
  }

  public checkForUpdates(): void {
    this.swUpdate.versionUpdates.pipe(
      filter((e): e is VersionReadyEvent => e.type === 'VERSION_READY')).subscribe(() => {
      this.promptUser()
    })
    // Deprecated
    // this.updates.available.subscribe(() => this.promptUser());
  }

  private promptUser(): void {
    if (confirm('Updates Available, Do you want to reload the page?')) {
      this.swUpdate.activateUpdate().then(() => this.document.location.reload());
    }
  }
}
