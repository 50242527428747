import {
  Component,
  OnInit,
  NgModule,
  Input,
  AfterViewInit,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
  HostListener,
  Inject,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FileSystemFileEntry, NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { SnackbarService } from '../snackbar/snackbar.service';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { UiModule } from '@smiths/ui';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UploadedFile } from '@smiths/data-access';

export type ImageItem = File & { src?: string };

@Component({
  selector: 'feature-imagebox',
  templateUrl: './imagebox.component.html',
  styleUrls: ['./imagebox.component.scss'],
})
export class ImageboxComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('Container') containerElement!: ElementRef<HTMLElement>;

  @Input() control = new FormControl();
  @Input() count = 5;
  @Input() readonly = false;
  @Input() uploadedImages?: { url: string }[];

  selectedIndex = 0;
  quantityArray!: number[];
  wrapperWidth = 0;
  loading = false;

  constructor(
    private snackbarService: SnackbarService,
    private host: ElementRef,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    @Inject('apiBaseUrl') public apiBaseUrl: string
  ) {}

  ngOnInit(): void {
    this.initImageBox();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.computeWrapperWidth();
    }, 1);
    // this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.uploadedImages) {
      this.initImageBox();
    }
  }

  onSelectImage(files: NgxFileDropEntry[]) {
    if (this.readonly) {
      return;
    }
    files.splice(this.count);
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.type === 'image/png' || file.type === 'image/jpeg') {
            if (this.control.value.length >= this.count) {
              return;
            }
            const image = {
              ...file,
              src: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)),
            };
            this.control.setValue([...this.control.value, image]);
            this.selectIndex(this.control.value.length - 1);
          } else {
            this.snackbarService.error('Format is not supported', 'Supported formats are .png and .jpeg.');
          }
        });
      }
    }
  }

  onRemoveImage(index: number) {
    const controlValue = this.control.value;
    controlValue.splice(index, 1);
    this.control.setValue(controlValue);
  }
  onPrevious() {
    if (this.quantityArray.length <= 1) {
      return;
    }
    if (this.selectedIndex === 0) {
      this.selectedIndex = this.count - 1;
      return;
    }
    this.selectedIndex--;
  }
  onNext() {
    if (this.quantityArray.length <= 1) {
      return;
    }
    if (this.selectedIndex === this.count - 1) {
      this.selectedIndex = 0;
      return;
    }
    this.selectedIndex++;
  }
  selectIndex(index: number) {
    this.selectedIndex = index;
  }

  private computeWrapperWidth() {
    this.wrapperWidth = this.containerElement.nativeElement.getBoundingClientRect().width;
  }

  private initImageBox() {
    if (
      this.uploadedImages ||
      (this.control.value && this.control.value.length > 0 && this.control.value[0].url)
    ) {
      const images = this.uploadedImages ?? this.control.value;
      this.quantityArray = new Array(images.length);
      this.control.setValue(
        images.map((x: any) => ({
          src: `${this.apiBaseUrl}${x.url}`,
        }))
      );
      this.count = images.length;
    } else {
      this.quantityArray = new Array(this.count);
      if (!this.control.value) {
        this.control.setValue([]);
      }
      if (!(this.control.value instanceof Array)) {
        this.control.setValue([this.control.value]);
      }
      const controlValue = this.control.value as any[];
      controlValue.splice(this.count);
      this.control.setValue(controlValue);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(e: any) {
    this.computeWrapperWidth();
  }
}

@NgModule({
  declarations: [ImageboxComponent],
  imports: [CommonModule, NgxFileDropModule, MatIconModule, UiModule, MatProgressSpinnerModule],
  exports: [ImageboxComponent],
})
export class ImageboxComponentModule {}
