<div class="ui-toggle">
  <div (click)="onClick('ar')" [class.active]="control.value === 'ar'">
    <mat-icon svgIcon="language-toggle-handle" class="toggle"></mat-icon>
    <mat-icon svgIcon="kuwait" class="flag"></mat-icon>
  </div>
  <div class="top-2" (click)="onClick('en')" [class.active]="control.value === 'en'">
    <mat-icon svgIcon="language-toggle-handle" class="toggle"></mat-icon>
    <mat-icon svgIcon="united-kingdom" class="flag"></mat-icon>
  </div>
</div>
