import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';

export interface CheckboxItem {
  text: string;
  textArabic?: string;
  name: string;
}

@Component({
  selector: 'ui-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
})
export class CheckboxGroupComponent implements OnInit {
  @Input() control = new FormControl('');
  @Input() items: CheckboxItem[] = [];
  @Input() label?: string;
  @Input() color: ThemePalette = 'primary';
  @Input() flowDirection: 'row' | 'column' = 'column';
  @Input() readonly = false;

  currentValue: any = {};
  isArabic = false;

  constructor() {}

  ngOnInit(): void {
    if (this.control.value) {
      if (this.control.value instanceof Array) {
        for (const textValue of this.control.value) {
          this.currentValue[textValue] = true;
        }
      } else {
        this.currentValue = this.control.value;
      }
    }
    for (const item of this.items) {
      this.currentValue[item.name] = this.currentValue[item.name] ?? false;
    }
    this.updateControlValue();
  }

  onChange(name: string, value: boolean) {
    this.currentValue[name] = value;
    this.updateControlValue();
  }

  switchLanguage(arabic: boolean) {
    this.isArabic = arabic;
  }

  private updateControlValue() {
    this.control.setValue(this.currentValue);
  }
}

@NgModule({
  declarations: [CheckboxGroupComponent],
  imports: [CommonModule, ReactiveFormsModule, MatCheckboxModule],
  exports: [CheckboxGroupComponent],
})
export class CheckboxGroupComponentModule {}
