import { Injectable } from '@angular/core';
import { BaseSnackbarData, SnackbarBaseComponent } from './snackbar-base/snackbar-base.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackbarService: MatSnackBar) { }

  open(options: BaseSnackbarData) {
    return this.snackbarService.openFromComponent(SnackbarBaseComponent, {
      panelClass: 'ui-snackbar-panel',
      duration: 5000,
      data: options
    })
  }
  success(title: string, message: string) {
    this.open({
      title,
      message,
      icon: 'check-circle',
      textClass: 'text-white',
      bgClass: 'bg-primary'
    })
  }
  error(title: string, message: string) {
    this.open({
      title,
      message,
      icon: 'ban',
      textClass: 'text-white',
      bgClass: 'bg-red',
      iconClass: 'text-secondary-light'
    })
  }
  warning(title: string, message: string) {
    this.open({
      title,
      message,
      icon: 'info-circle',
      textClass: 'text-gray-700',
      bgClass: 'bg-secondary-light-alt border border-gray-500',
      iconClass: 'text-red'
    })
  }
}
