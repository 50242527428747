<div #Wrapper class="relative w-full">
  <mat-form-field class="ui-field ui-field-phone-input-fix" appearance="outline">
    <p class="field-label" *ngIf="label">{{ label }}</p>
    <ngx-mat-intl-tel-input
      #Field
      class="ui-phone-input"
      (countryChanged)="countryChanged($event)"
      [formControl]="control"
      [preferredCountries]="['kw', 'us']"
      [enablePlaceholder]="true"
      format="national"
    ></ngx-mat-intl-tel-input>
    <mat-error *ngIf="control.errors?.validatePhoneNumber">Phone number is incorrect.</mat-error>
  </mat-form-field>
</div>
