import { Component, NgModule, Input, ChangeDetectionStrategy } from '@angular/core';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() type: 'table' | 'form' = 'form';
  formArray = Array(8).fill(0);
}

@NgModule({
  declarations: [LoaderComponent],
  imports: [ContentLoaderModule, CommonModule],
  exports: [LoaderComponent],
})
export class LoaderComponentModule {}
