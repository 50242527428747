import { Component, NgModule, Inject, ElementRef, Renderer2 } from '@angular/core';
import { UiModule } from '@smiths/ui';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface PromptDialogData {
  title: string;
  message?: string;
  yesButtonText?: string;
  noButtonText?: string;
  // compact?: boolean;
}
export type PromptDialogResult = boolean;

@Component({
  selector: 'feature-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<PromptDialogComponent, PromptDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: PromptDialogData,
    private host: ElementRef,
    private renderer2: Renderer2
  ) {
    // this.renderer2.setStyle(this.host.nativeElement, 'width', this.data.compact ? '27vw' : '47vw');
  }

  closeDialog(result?: PromptDialogResult) {
    this.dialogRef.close(result);
  }
}

@NgModule({
  declarations: [PromptDialogComponent],
  imports: [UiModule],
  exports: [PromptDialogComponent]
})
export class PromptDialogComponentModule {
}
