<div class="table-wrapper">
  <pbl-ngrid
    #table
    blockUi
    class="feature-table pbl-ngrid-cell-ellipsis"
    [dataSource]="dataSource"
    [columns]="tableDefinition"
    [hideColumns]="hiddenColumns ?? []"
    [minDataViewHeight]="-1 * (rowsToView ?? perPage)"
    [matCheckboxSelection]="$any(selectable ? 'selection' : undefined)"
    matCheckboxSelectionColor="primary"
    targetEvents
    (rowClick)="onRowClick($event)"
    matSort
  >
    <!-- TODO: Currently Material's tooltip has bug -->
    <!--  <ng-container-->
    <!--    cellTooltip-->
    <!--    [message]="getTooltipMessage"-->
    <!--    tooltipClass="cell-tooltip"-->
    <!--    position="above"-->
    <!--  ></ng-container>-->
    <div @fade *pblNgridBlockUiDef class="absolute w-full h-full bg-white loading-block">
      <mat-progress-bar class="top-17 absolute" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="flex mb-13 relative" *pblNgridOuterSection="'top'">
      <div>
        <p style="min-width: 7rem" class="text-xl lexend-deca">{{ title }}</p>
        <p class="text-sm mt-1" *ngIf="showTotalResult">{{ totalItems }} results</p>
      </div>
      <ui-select
        style="width: 14.5rem"
        placeholder="Actions"
        class="ml-4"
        appearance="filled"
        [items]="actionDropdownItems"
        [resetAfterSelect]="true"
        [class.hidden]="hideBulkActionDropdown"
        (itemSelect)="onBulkAction($event)"
      ></ui-select>
      <div class="ml-auto"><!-- Spacer --></div>
      <ui-button
        *ngIf="exportable"
        (click)="onExport()"
        class="mr-6"
        label="Export"
        appearance="stroked"
        icon="export"
      ></ui-button>
      <ui-button
        [routerLink]="addButtonRouteLink"
        (click)="onAdd()"
        label="{{ addButtonText }}"
        color="primary"
        icon="add-square"
        [class.hidden]="hideAddButton"
      ></ui-button>
      <ui-button
        [routerLink]="showMoreButtonRouteLink"
        label="{{ showMoreButtonText }}"
        color="primary"
        [class.hidden]="!showMoreButtonText"
      ></ui-button>
    </div>

    <div class="flex flex-col select-none w-inherit h-inherit" *pblNgridHeaderCellDef="'*'; col as col">
      <p
        class="text-xs text-gray-400 {{
          !col.data.align
            ? 'text-left'
            : col.data.align === 'left'
            ? 'text-left'
            : col.data.align === 'center'
            ? 'text-center'
            : 'text-right'
        }}"
      >
        {{ col.label }}
      </p>
      <div (click)="$event.stopPropagation()" class="w-full py-2 mt-auto">
        <ng-container *ngIf="col.data.searchControl" [ngSwitch]="col.type?.name ?? ''">
          <div *ngSwitchCase="'image'"></div>
          <ui-select
            *ngSwitchCase="'enum'"
            [items]="col.data.enumItems"
            [control]="col.data.searchControl"
            [mini]="true"
            appearance="filled"
            noValue="All"
          ></ui-select>
          <ui-field
            *ngSwitchCase="'date-array'"
            controlType="date-range"
            [control]="col.data.searchControl"
            [endControl]="col.data.endDateSearchControl"
            [mini]="true"
            prefixIcon="search"
          ></ui-field>
          <ui-field
            *ngSwitchCase="'date'"
            controlType="date-range"
            [control]="col.data.searchControl"
            [endControl]="col.data.endDateSearchControl"
            [mini]="true"
            prefixIcon="search"
          ></ui-field>
          <ui-field
            *ngSwitchDefault
            [control]="col.data.searchControl"
            [mini]="true"
            placeholder="Search"
            prefixIcon="search"
          ></ui-field>
        </ng-container>
      </div>
    </div>

    <div *pblNgridCellTypeDef="'image'; col as col; value as value">
      <div
        class="
          w-16
          h-16
          relative
          border border-primary-dark
          rounded-full
          overflow-hidden
          bg-white
          flex
          items-center
          justify-center
        "
      >
        <img
          imageLoader
          *ngIf="value"
          style="min-height: 100%; min-width: 100%; object-fit: cover; flex-shrink: 0"
          [src]="value"
          loading="lazy"
          alt=""
        />
      </div>
    </div>
    <div *pblNgridCellTypeDef="'array'; col as col; value as value">
      <ui-table-list [list]="value"></ui-table-list>
    </div>
    <div *pblNgridCellTypeDef="'date-array'; col as col; value as value">
      <ui-table-list [list]="value"></ui-table-list>
    </div>
    <div *pblNgridCellDef="'*'; col as col; value as value; row as row">
      <div
        (click)="onCellClick(value, row, $event, col.data.onClick)"
        [class.cursor-pointer]="!!col.data.onClick"
        [title]="value"
        class="{{ col.data.classList }} {{ col.data.dynamicClassList }}"
        [innerHTML]="
          col.type?.name === 'enum'
            ? (value | titlecase)
            : col.type?.name === 'date'
            ? (value | date: 'yyyy/MM/dd')
            : value
        "
      ></div>
    </div>

    <div *pblNgridCellDef="'action'; row as row" class="actions-panel">
      <button
        *ngFor="let action of actions"
        (click)="onAction($event, row, action)"
        mat-button
        class="mat-button-custom"
        [class.w-auto]="action.text !== undefined || action.dynamicText !== undefined"
      >
        <mat-icon *ngIf="action.icon" [svgIcon]="action.icon"></mat-icon>
        <p class="font-semibold text-sm" *ngIf="action.text">{{ action.text }}</p>
        <p class="font-semibold text-sm" *ngIf="action.dynamicText">{{ action.dynamicText(row) }}</p>
      </button>
    </div>

    <div *pblNgridOuterSection="'bottom'">
      <div *ngIf="showPagination" class="flex pt-8 pb-6 px-4">
        <ui-select
          appearance="filled"
          [control]="perPageControl"
          [items]="perPageOptions"
          (itemSelect)="onPerPageChange($event)"
        ></ui-select>
        <div class="w-full"><!-- Spacer --></div>
        <ui-pagination
          [page]="currentPage"
          (pageChange)="onPageChange($event)"
          [autoHide]="true"
          [pageCount]="totalPages"
        ></ui-pagination>
      </div>
    </div>
  </pbl-ngrid>
</div>
