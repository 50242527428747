import { MatIconModule } from '@angular/material/icon';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { LoaderComponentModule, UiButtonComponentModule } from '@smiths/ui';
import { NotificationMiniListComponentModule } from './notification-mini-list/notification-mini-list.component';
import { NotificationSettingComponentModule } from './notification-setting/notification-setting.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'feature-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
})
export class NotificationDialogComponent {
  nextIndex(stepper: MatStepper) {
    stepper.next();
  }
  previousIndex(stepper: MatStepper) {
    stepper.previous();
  }
}

@NgModule({
  declarations: [NotificationDialogComponent],
  imports: [
    CommonModule,
    UiButtonComponentModule,
    LoaderComponentModule,
    NotificationSettingComponentModule,
    MatStepperModule,
    MatIconModule,
    NotificationMiniListComponentModule,
  ],
  exports: [NotificationDialogComponent],
})
export class NotificationDialogComponentModule {}
