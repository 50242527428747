import { ImageCropperDialogComponent, ImageCropperDialogData, ImageCropperDialogResult } from './image-cropper-dialog/image-cropper-dialog.component';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  PromptDialogComponent,
  PromptDialogData,
  PromptDialogResult,
} from './prompt-dialog/prompt-dialog.component';
import { ComponentType } from '@angular/cdk/overlay';
import { MessageDialogComponent, MessageDialogData } from './message-dialog/message-dialog.component';
import { PhotoDialogComponent, PhotoDialogData } from './photo-dialog/photo-dialog.component';
import { MapDialogComponent, MapDialogData, MapDialogResult } from './map-dialog/map-dialog.component';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  open<C, D, R>(component: ComponentType<C>, options: MatDialogConfig<D>) {
    return this.dialog.open<C, D, R>(component, {
      panelClass: 'ui-dialog-panel',
      autoFocus: false,
      ...options,
    });
  }

  prompt(data: PromptDialogData) {
    return this.open<PromptDialogComponent, PromptDialogData, PromptDialogResult>(PromptDialogComponent, {
      data,
    });
  }

  message(data: MessageDialogData) {
    return this.open<MessageDialogComponent, MessageDialogData, never>(MessageDialogComponent, {
      data,
    });
  }

  photo(data: PhotoDialogData) {
    return this.open<PhotoDialogComponent, PhotoDialogData, never>(PhotoDialogComponent, {
      data,
      width: '472px',
      maxWidth: '90vw',
      maxHeight: '90vh',
    });
  }

  map(data: MapDialogData) {
    return this.open<MapDialogComponent, MapDialogData, MapDialogResult>(MapDialogComponent, {
      data,
      width: '90vw',
      maxHeight: '90vh',
    });
  }

  notification() {
    return this.open(NotificationDialogComponent, {
      width: '30vw',
      height: '80vh',
      position: { top: '116px', right: '50px' },
    });
  }

  changePassword() {
    return this.open<ChangePasswordDialogComponent, never, never>(ChangePasswordDialogComponent, {
      width: '375px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      position: {
        top: '116px',
        right: '30px',
      },
    });
  }

  imageCropperDialog(data:ImageCropperDialogData){
    return this.open<ImageCropperDialogComponent,ImageCropperDialogData,ImageCropperDialogResult>(ImageCropperDialogComponent,{
      data,
      width:'30%',
      height:'60%',
    });
  }
}
