<p class="text-xs -mt-3">Map</p>
<div class="map-wrapper">
  <google-map
    *ngIf="latControl.value && lngControl.value; else noMap"
    height="100%"
    width="100%"
    [center]="center"
  >
    <map-marker [position]="{ lat: +latControl.value, lng: +lngControl.value }"></map-marker>
  </google-map>
  <ng-template #noMap>
    <div (click)="openMapDialog()" class="flex justify-center items-center h-full text-center cursor-pointer">
      <div>
        <mat-icon svgIcon="map-location"></mat-icon>
        <p class="text-primary-dark font-semibold text-sm">
          Choose location on map
        </p>
      </div>
    </div>
  </ng-template>
</div>

<p
  *ngIf="latControl.value && lngControl.value"
  (click)="openMapDialog()"
  class="text-sm text-primary-dark font-medium cursor-pointer mt-3"
>
  Edit location
</p>
