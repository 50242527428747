import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthApiService } from '.';
import { User } from '../../models/auth.model';
import { LoginAction, LogoutAction, UpdateProfileAvatarAction } from './state/auth.action';
import { AuthState, AUTH_STATE_TOKEN } from './state/auth.state';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private store: Store,private authApi: AuthApiService) { }

  get state() {
    return this.store.selectSnapshot(AUTH_STATE_TOKEN);
  }
  get state$() {
    return this.store.select(AUTH_STATE_TOKEN);
  }
  getAuthSnapShot() {
    return this.store.selectSnapshot(AuthState.getState);
  }
  getAuthObserver() {
    return this.store.select((state) => state.auth.rememberMe);
  }
  getUserObserver(): Observable<User> {
    return this.store.select((state) => state.auth.user);
  }

  isUserAuthenticated(): boolean {
    return this.store.selectSnapshot(AuthState.isAuthenticated);
  }

  login(
    email: string,
    password: string,
    rememberMe: boolean,
    fcmToken?: string,
  ) {
    return this.store.dispatch(
        new LoginAction(email, password, rememberMe, fcmToken)
    );
  }

  sendResetLink(email: string) {
    return this.authApi.sendResetLink(email);
}

  resetPassword(token: string, newPassword: string) {
    return this.authApi.userResetPassword(token, newPassword)
  }

  logout() {
    this.store.dispatch(new LogoutAction());
  }

  updateProfile(avatar: number) {
    this.store.dispatch(new UpdateProfileAvatarAction(avatar));
  }
}

