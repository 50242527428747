import { CommonModule } from '@angular/common';
import { Component, NgModule, Input } from '@angular/core';

@Component({
  selector: 'ui-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss'],
})
export class TableListComponent {
  @Input() list: any[] = [];
  isExpanded = false;

  onToggle() {
    this.isExpanded = !this.isExpanded;
  }
}

@NgModule({
  declarations: [TableListComponent],
  imports: [CommonModule],
  exports: [TableListComponent],
})
export class TableListComponentModule {}
