<div [formGroup]='formGroup' class="flex flex-col justify-center items-center h-full p-5 md:p-0">
    <div class="text-left">
        <p class="capitalize mb-4 text-2xl font-normal">admin login</p>
        <div class='flex flex-col justify-center'>
            <ui-field
            [label]="'email'"
            [placeholder]="'smiths@gmail.com'"
            [suffixIcon]="'profile-fill'"
            [control]="$any(formGroup.controls.email)"
            class="mb-2 w-72 md:w-80"
            ></ui-field>
            <div class='relative'>
                <ui-field
                [label]="'password'"
                [placeholder]="'enter your password'"
                [inputType]="'password'"
                [control]="$any(formGroup.controls.password)"
                class="w-72 md:w-80"
                ></ui-field>
                <a [routerLink]='"../forgot-password"' class='forget-password-link'>Forgot Password?</a>
            </div>
            <div class="flex flex-row justify-between w-72 items-baseline flex-wrap mb-3 md:w-80">
            <mat-checkbox class="text-primary-900 font-semibold mb-3 w-max" formControlName="rememberMe">Remember Me</mat-checkbox>
            <a [routerLink]='"../forgot-password"' class='--mobile-forget-password-link'>Forgot Password?</a>
                <ui-button
            [label]="'Login'"
            class="h-11 w-36 sm:float-right"
            (click)='onLogin()'
            [isLoading]="loading"
            [disabled]='!formGroup.valid'
            ></ui-button>
            </div>
        </div>
        <div>

        </div>
    </div>
</div>
