import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { AuthService } from '@smiths/data-access';
import { SnackbarService } from '@smiths/feature';

interface ErrorObj {
  data: { id: number; message: string }[];
  error: string;
  message: string;
  statusCode: string;
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private snackbarService: SnackbarService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((e: HttpErrorResponse) => {
        console.log(e)
        if (e.status === 401 || e.status === 403) {
          this.authService.logout();
        } else if (e.error) {
          this.snackbarService.error('Server error', (e.error as ErrorObj).message);
        } else {
          this.snackbarService.error('Server error', '');
        }
        return next.handle(request);
      })
    );
  }
}
