import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

export interface BaseSnackbarData {
  title: string;
  message: string;
  icon: string;
  textClass: string;
  bgClass: string;
  iconClass?: string;
}

@Component({
  selector: 'feature-snackbar-base',
  templateUrl: './snackbar-base.component.html',
  styleUrls: ['./snackbar-base.component.scss'],
})
export class SnackbarBaseComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: BaseSnackbarData,
    private snackbarRef: MatSnackBarRef<SnackbarBaseComponent>
  ) {}

  ngOnInit(): void {}
  close() {
    this.snackbarRef.dismissWithAction();
  }
}

@NgModule({
  declarations: [SnackbarBaseComponent],
  imports: [CommonModule, MatSnackBarModule, MatIconModule],
  exports: [SnackbarBaseComponent],
})
export class SnackbarBaseComponentModule {}
