<div class="grid grid-cols-5 p-2">
  <div class="header-title col-span-full mb-8 gap-8" [ngClass]="{'xl:col-span-1 xl:mb-0': !fullWidth}">
    <div *ngIf="title" class="flex items-center">
      <mat-icon (click)="onClickBack()" class="text-primary-800 mr-1 cursor-pointer">arrow_back_ios</mat-icon>
      <span class="text-xl">{{ title }}</span>
    </div>
    <div class="flex" [ngClass]="{'xl:hidden': !fullWidth}">
      <ng-template [ngTemplateOutlet]="tabsTemplate"></ng-template>
    </div>
  </div>
  <div class="col-span-full xl:col-span-3" [ngClass]="{'xl:col-span-full': !title || fullWidth}">
    <div class="hidden mb-8" [ngClass]="{'xl:flex': !fullWidth}">
      <ng-template [ngTemplateOutlet]="tabsTemplate"></ng-template>
    </div>
    <ng-content></ng-content>
  </div>
</div>

<ng-template #tabsTemplate>
  <div class="flex flex-wrap gap-2">
    <button
      *ngFor="let tab of tabs"
      mat-raised-button
      (click)="onSelectTab(tab)"
      class="tab-btn"
      [class.active]="tab.active"
      [disabled]="tab.disabled"
    >
      {{ tab.title }}
    </button>
  </div>
</ng-template>
