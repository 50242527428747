import { Component, EventEmitter, Input, NgModule, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ui-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() pageCount = 5;
  @Input() page = 1;
  @Input() visibleCount = 5;
  @Input() autoHide = false;

  @Output() pageChange = new EventEmitter<number>();

  pageArray: number[] = [];
  leftEllipsis = false;
  rightEllipsis = false;

  constructor() { }

  private setupPages() {
    const pages: number[] = [];
    const sideCount = (this.visibleCount - 1) / 2;
    let startPage = this.page - Math.ceil(sideCount);
    let endPage = this.page + Math.floor(sideCount);
    if (startPage < 1) {
      while(startPage < 1) {
        startPage++;
        endPage++;
      }
      if (endPage > this.pageCount) {
        endPage = this.pageCount;
      }
    } else if (endPage > this.pageCount) {
      while(endPage > this.pageCount) {
        startPage--;
        endPage--;
      }
      if (startPage < 1) {
        startPage = 1;
      }
    }

    for(let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    if (!pages.includes(1)) {
      pages.shift();
      pages.unshift(1);
    }
    if (!pages.includes(this.pageCount)) {
      pages.pop();
      pages.push(this.pageCount);
    }
    try {
      this.leftEllipsis = pages[1] > 2;
      this.rightEllipsis = pages[pages.length - 2] < this.pageCount - 1;
    } catch {
      this.leftEllipsis = false;
      this.rightEllipsis = false;
    }

    this.pageArray = pages;
  }

  ngOnInit(): void {
    this.setupPages();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.pageCount) {
      this.setupPages();
    }
  }

  onNext() {
    if (this.page === this.pageCount) {
      return
    }
    this.changePage(this.page + 1);
  }
  onPrevious() {
    if (this.page === 1) {
      return
    }
    this.changePage(this.page - 1);
  }
  onPageSelect(page: number) {
    this.changePage(page);
  }

  changePage(page: number) {
    this.page = page;
    this.pageChange.emit(page);
    this.setupPages();
  }
}

@NgModule({
  declarations: [PaginationComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [PaginationComponent]
})
export class PaginationComponentModule {
}
