<div class="flex flex-col justify-between">
  <div mat-dialog-title class="flex justify-end">
    <mat-icon class="cursor-pointer" (click)="closeDialog({ result: false, image: undefined })"
      >close</mat-icon
    >
  </div>
  <div class="rounded-lg border-solid border-2 border-primary-dark">
    <image-cropper
      [containWithinAspectRatio]="true"
      [imageFile]="data.image!"
      (imageCropped)="imageCropped($event)"
    ></image-cropper>
  </div>
  <div class="flex flex-row w-full items-center h-30 justify-between mt-4">
    <div class="w-30 h-full mr-2">
      <img [src]="showCroppedImage" height="100%"/>
    </div>
    <ui-button
      label="Cancel"
      color="primary"
      (clicked)="closeDialog({ result: false, image: undefined })"
    ></ui-button>
    <ui-button
      class="ml-2"
      label="Crop"
      color="primary"
      (clicked)="closeDialog({ result: true, image: imageHaveCropped })"
    ></ui-button>
  </div>
</div>
