import { Component, NgModule } from '@angular/core';
import { UiModule } from '@smiths/ui';

@Component({
  selector: 'smiths-reset-link-sent',
  templateUrl: './reset-link-sent.component.html',
  styleUrls: ['./reset-link-sent.component.css']
})
export class ResetLinkSentComponent {}

@NgModule({
  declarations: [ResetLinkSentComponent],
  imports: [UiModule],
  exports: [ResetLinkSentComponent]
})
export class ResetLinkSentComponentModule {
}
