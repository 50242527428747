<div class="flex flex-col h-full py-8 px-10">
  <div class="flex flex-row justify-between mb-4">
    <p class="lexend-deca font-normal text-xl text-gray-800">Notifications</p>
    <ui-button appearance="stroked" (click)="goToSetting.emit()" label="Setting" icon="setting"></ui-button>
  </div>
  <div *ngIf="notifications$ | async as notifications; else loader" class="flex flex-col notifications">
    <div class="my-5" *ngFor="let notification of notifications">
      <p class="montserrat font-medium text-lg text-gray-700">{{ notification.body }}</p>
      <div class="flex flex-row justify-start">
        <p class="text-gray-light montserrat font-medium text-sm">{{ notification.title }}</p>
        <p
          (click)="onActionClick(notification)"
          class="cursor-pointer ml-2 font-medium text-sm montserrat text-primary-800"
        >
          {{
            notification.state === 'new_user'
              ? 'VIEW PROFILE'
              : notification.state === 'vault_request'
              ? 'ESTIMATE PRICE'
              : notification.state === 'update_vault'
              ? 'UPDATE PRICE'
              : ''
          }}
        </p>
      </div>
      <p class="font-semibold text-gray-light text-sm mt-1">
        {{ notification.created_at | date: 'yyyy/MM/dd' }}
      </p>
    </div>
  </div>
  <ui-button class="w-full mt-4" label="View all notifications" (click)="viewAllNotifications()"></ui-button>
</div>

<ng-template #loader>
  <ui-loader class="h-full"></ui-loader>
</ng-template>
