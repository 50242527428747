import { CommonModule } from '@angular/common';
import { Component, OnInit, NgModule, Inject } from '@angular/core';
import { AuthApiService } from '@smiths/data-access';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { SnackbarService } from '../../snackbar/snackbar.service';
import { UiModule } from '@smiths/ui';
import { MatDialogRef } from '@angular/material/dialog';

interface PasswordForm {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

@Component({
  selector: 'feature-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent implements OnInit {
  formGroup: FormGroup<ControlsOf<PasswordForm>>;
  loading = false;

  constructor(
    private authApiService: AuthApiService,
    public snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject('passwordPattern') public passwordPattern: string
  ) {
    this.formGroup = new FormGroup({
      oldPassword: new FormControl('', Validators.required) as any,
      newPassword: new FormControl('', [Validators.required, Validators.pattern(this.passwordPattern)]),
      confirmNewPassword: new FormControl('', [Validators.required, this.passwordValidator]),
    });
  }

  ngOnInit(): void {}

  passwordValidator: ValidatorFn = (): ValidationErrors | null => {
    let newPassword = this.formGroup?.controls.newPassword.value;
    let confirmNewPassword = this.formGroup?.controls.confirmNewPassword.value;
    return newPassword === confirmNewPassword ? null : { notTheSame: true };
  };

  onSave() {
    if (this.formGroup.valid) {
      this.loading = true;
      const values = this.formGroup.value;
      this.authApiService
        .userChangePassword(values.oldPassword, values.newPassword)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.snackbarService.success('Successfull action', 'Password successfully changed.');
            this.loading = false;
            this.dialogRef.close();
          },
          error: () => {
            this.loading = false;
          },
        });
    } else {
      this.formGroup.markAllAsTouched();
    }
  }
}

@NgModule({
  declarations: [ChangePasswordDialogComponent],
  imports: [CommonModule, UiModule],
  exports: [ChangePasswordDialogComponent],
})
export class ChangePasswordDialogComponentModule {}
