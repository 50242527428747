import { UploadedFile } from './uploaded-file.model';
import { User } from './auth.model';

export interface VaultList {
  list: Vault[];
}

export type VaultQuestionType = 'textfield' | 'imagebox' | 'radiobutton' | 'checkbox' | 'dropdown';
export type VaultTextFieldInputType = 'integer' | 'string' | 'character';

export interface Vault {
  id: number;
  category_name_en: string;
  category_name_ar: string;
  status: 'enable' | 'disable';
  number_of_requests: number;
  number_of_steps: number;
  published_at: string;
  created_by: any;
  updated_by: any;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  deleted_by: any;
  image: UploadedFile;
  steps: VaultStep[];
  total_request: number;
}

export interface VaultStep {
  id: number;
  name_en: string;
  name_ar: string;
  vault: number;
  published_at: string;
  created_by: any;
  updated_by: any;
  created_at: string;
  updated_at: string;
  order: number;
  image: UploadedFile;
  questions: VaultQuestion[];
}

export interface VaultQuestion {
  answers: VaultSelectionAnswer[];
  created_at: string;
  created_by: any | null;
  id: number;
  input_type: VaultTextFieldInputType | null;
  is_required: boolean;
  number_of_images: number | null;
  order: number;
  published_at: string;
  question: string;
  question_ar: string;
  step: number;
  title: string;
  title_ar: string;
  type: VaultQuestionType;
  updated_at: string;
  updated_by: any | null;
}

export interface VaultModel {
  category_name_en: string;
  category_name_ar: string;
  status: 'enable' | 'disable';
  image: number;
  steps: VaultStepModel[];
}

export interface VaultStepModel {
  id?: number;
  name_en: string;
  name_ar: string;
  image?: number;
  order: number;
  questions: VaultQuestionModel[];
}

export interface VaultQuestionModel {
  id?: number;
  title: string;
  question: string;
  title_ar: string;
  question_ar: string;
  type: VaultQuestionType;
  answers?: { answer: string; answer_ar: string }[];
  input_type?: VaultTextFieldInputType;
  number_of_images?: number;
  is_required: boolean;
  order: number;
}

export interface VaultRequest {
  id: number;
  ref_id: string;
  user: User;
  vault: Vault;
  published_at: string;
  created_by: null | string;
  updated_by: number;
  created_at: string;
  updated_at: null | string;
  title: string;
  initial_price: number;
  initial_price_setter: string;
  last_estimated_price: number;
  last_estimation_date: string;
  deleted_at: null | string;
  deleted_by: null | string;
  images: UploadedFile[];
  answers: VaultAnswer[];
  estimated_prices: VaultRequestEstimatedPrice[];
}

export interface VaultAnswer {
  id: number;
  question: VaultQuestion;
  text_answer: null | string;
  selection_answer: VaultSelectionAnswer | null;
  request: number;
  published_at: string;
  created_by: null | string;
  updated_by: null | string;
  created_at: string;
  updated_at: string;
  images: UploadedFile[];
}

export interface VaultSelectionAnswer {
  id: number;
  answer: string;
  question: number;
  published_at: string;
  created_by: null;
  updated_by: null;
  created_at: string;
  updated_at: string;
  answer_ar: string;
}

export interface VaultRequestEstimatedPrice {
  id: number;
  price: number;
  request: number;
  published_at: string;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
}
