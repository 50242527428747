import { Component } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { AuthService, NotificationService, PWAService } from '@smiths/data-access';
import { environment } from '../environments/environment';
import { initializeApp, getApps } from 'firebase/app';
import { Router } from '@angular/router';

@Component({
  selector: 'smiths-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private pwaService: PWAService,
    swPush: SwPush,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.pwaService.checkForUpdates();

    swPush.messages.subscribe(() => {
      if (this.authService.getAuthSnapShot()?.jwt) {
        this.notificationService.updateUnreadCount();
      }
    });

    swPush.notificationClicks.subscribe(() => {
      this.router.navigate(['notifications']);
    });

    if (getApps().length < 1) {
      initializeApp(environment.firebase);
    }
  }
}
