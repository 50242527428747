<div class="flex flex-col h-full montserrat font-medium py-8 px-7">
  <div class="-ml-4">
    <ui-navigation-bar (click)="goToNotification.emit()" text="Notification's setting"></ui-navigation-bar>
  </div>

  <div class="flex flex-col mt-3" *ngIf="!loading && settingFormGroup; else loader">
    <div class="flex flex-col text-base gap-10 my-5">
      <mat-checkbox [formControl]="settingFormGroup.controls.newUserNotification"
        >Notify me when a new user joined</mat-checkbox
      >
      <mat-checkbox [formControl]="settingFormGroup.controls.vaultRequestNotification"
        >Notify me when user requested for estimating price</mat-checkbox
      >
      <p>Notify me when it is the time for updating user vaults</p>
    </div>
    <div class="ml-6 flex flex-col">
      <p>Set reminder :</p>
      <ui-radio
        [control]="settingFormGroup.controls.vaultUpdateReminderDuration"
        [items]="reminderDurationItems"
      ></ui-radio>
    </div>
  </div>

  <ui-button
    [isLoading]="saving"
    class="ml-auto mt-auto w-36"
    (click)="onSaveClick()"
    label="Save"
  ></ui-button>
</div>

<ng-template #loader>
  <ui-loader></ui-loader>
</ng-template>
