import { Directive, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[forNumber]',
})
export class ForNumberDirective {
  constructor(private templateRef: TemplateRef<never>, private viewContainer: ViewContainerRef) {}

  @Input('forNumber') set count(count: number) {
    this.viewContainer.clear();
    for (let i = 0; i < count; i++) {
      this.viewContainer.createEmbeddedView(this.templateRef, {
        $implicit: i,
      });
    }
  }
}

@NgModule({
  declarations: [ForNumberDirective],
  imports: [],
  exports: [ForNumberDirective]
})
export class ForNumberDirectiveModule {
}
