import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface StrapiOptions {
  search?: StrapiSearch[];
  sort?: { [p: string]: 'asc' | 'desc' };
  pagination?: {
    page: number;
    perPage: number;
  };
}
export interface StrapiSearch {
  strategy?: 'eq' | 'contains' | 'lte' | 'gte' | 'notNull' | 'null';
  key: string;
  term: string;
}

export interface StrapiPaginatedResponse<T> {
  list: T[];
  total_count?: number;
}

@Injectable({
  providedIn: 'root',
})
export class StrapiApiService {
  constructor(private http: HttpClient) {}

  createGetRequest<T>(
    url: string,
    mapFn?: (x: any) => StrapiPaginatedResponse<T>
  ): (options?: StrapiOptions) => Observable<StrapiPaginatedResponse<T>> {
    return (options?: StrapiOptions) => {
      let endpoint = url;
      let params: string[] = [];
      if (url.indexOf('?') !== -1) {
        const split = url.split('?');
        endpoint = split[0];
        try {
          params = split[1].split('&');
          // eslint-disable-next-line no-empty
        } catch {}
      }
      if (options?.pagination) {
        params.push(`_limit=${options.pagination.perPage}`);
        params.push(
          `_start=${
            options.pagination.page === -1 ? 0 : (options.pagination.page ?? 0) * options.pagination.perPage
          }`
        );
      }
      if (options?.sort) {
        this.iterateObject(options.sort, (key, value) => {
          params.push(`_sort=${key}:${value.toUpperCase()}`);
        });
      }
      if (options?.search) {
        options.search.forEach((value) => {
          if (value.strategy === 'null' || value.strategy === 'notNull') {
            params.push(`${value.key}_${value.strategy === 'null' ? 'eq' : 'ne'}=null`);
          } else if (
            value.strategy === 'eq' ||
            (value.term !== '' && value.term !== undefined && value.term !== null)
          ) {
            const searchStrategy = value.strategy === 'eq' ? '' : value.strategy ?? 'contains';
            params.push(`${value.key}${searchStrategy ? '_' + searchStrategy : ''}=${value.term}`);
          }
        });
      }
      return this.http
        .get<StrapiPaginatedResponse<T>>(`${endpoint}?${params.join('&')}`)
        .pipe(map(mapFn ?? ((x) => x)));
    };
  }

  generateMultiIdParam(ids: number[], key = 'id') {
    return ids.map((id, index) => `${key}[${index}]=${id}`).join('&');
  }

  generateMultiIdBody(ids: number[], key = 'id'): any {
    return ids.reduce((pre, cur, index) => {
      pre[key + '[' + index + ']'] = cur;
      return pre;
    }, {} as any);
  }

  private iterateObject<V>(object: { [p: string]: V }, callback: (key: string, value: V) => void) {
    for (const key of Object.keys(object)) {
      callback(key, (object as any)[key] as V);
    }
  }
}
