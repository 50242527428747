<div formGroup='formGroup' class="flex flex-col justify-center items-center h-full">
    <div class="text-left">
        <p class="capitalize mb-2 ml-2 text-2xl font-normal">Reset Password?</p>
        <p class="mb-6 ml-2 w-72">Please enter your email and we’ll send you an email to reset your password.</p>
            <ui-field
            [label]="'email'"
            [placeholder]="'smiths@gmail.com'"
            [control]='$any(formGroup.controls.email)'
            [suffixIcon]="'profile-fill'"
            class="w-72 md:w-80"
            ></ui-field>
        <div>
            <ui-button
            [label]="'Send'"
            class="h-11 w-36 ml-2"
            [isLoading]='loading'
            [disabled]='!this.formGroup.valid'
            (click)='resetPassword()'
            ></ui-button>
        </div>
    </div>
</div>
