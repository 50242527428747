import { Component, NgModule } from '@angular/core';
import { UiModule } from '@smiths/ui';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Actions, ofActionCompleted } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { AuthService, LoginAction } from '@smiths/data-access';
import { getToken, getMessaging } from 'firebase/messaging';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'smiths-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  formGroup: FormGroup;
  sub?: Subscription;
  loading = false;

  constructor(private authService: AuthService, private actions$: Actions) {
    this.formGroup = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      password: new FormControl('', Validators.required),
      rememberMe: new FormControl(false),
    });
  }

  onLogin() {
    if (this.formGroup.valid) {
      this.loading = true;
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          let currentRegistration;
          if (registrations.length === 0) {
            navigator.serviceWorker
              .register('/firebase-message-sw.js')
              .then((registration) => {
                currentRegistration = registration;
              });
          } else {
            [currentRegistration] = registrations;
          }

          getToken(getMessaging(), { serviceWorkerRegistration: currentRegistration, vapidKey: environment.vapidKey })
            .then((fcmToken) => this.login(fcmToken))
            .catch((err) => {
              console.log(
                'Unable to get permission to notify.',
                err
              );
              this.login();
            });
        })
        .catch((err) => {
          console.log('Unable to get permission to notify.', err);
          this.login();
        });
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  private login(fcmToken?: string) {
    this.loading = true;
    const val = this.formGroup.value;
    this.authService.login(val.email, val.password, val.rememberMe, fcmToken);
    this.sub = this.actions$.pipe(ofActionCompleted(LoginAction)).subscribe({
      next: () => {
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    })
  }
}


@NgModule({
  declarations: [LoginComponent],
  imports: [RouterModule, ReactiveFormsModule, UiModule, MatCheckboxModule],
  exports: [LoginComponent],
})
export class LoginComponentModule {}
