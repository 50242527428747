import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Affiliate, AffiliateList, AffiliateModel } from '../models';
import { StrapiApiService } from '../utils';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root',
})
export class AffiliatesApiService {
  constructor(
    private httpClient: HttpClient,
    private strapiApiService: StrapiApiService,
    private sharedService: SharedService,
  ) {}

  getListForTable() {
    return this.strapiApiService.createGetRequest<Affiliate>('/affiliates');
  }

  getAffiliates() {
    return this.httpClient.get<AffiliateList>('/affiliates');
  }

  getAffiliate(id: number) {
    return this.httpClient.get<Affiliate>(`/affiliates/${id}`);
  }

  createAffiliate(model: AffiliateModel) {
    return this.httpClient.post<Affiliate>(`/affiliates`, model);
  }

  updateAffiliate(model: AffiliateModel, id: number) {
    return this.httpClient.put<Affiliate>(`/affiliates/${id}`, model);
  }

  deleteAffiliates(ids: number[]) {
    return this.sharedService.delete('/delete-affiliates', ids);
  }

  export() {
    return this.httpClient.get<{ link: string }>(`/affiliates-export`);
  }
}
