import { ImageCropperDialogComponent } from './../dialog/image-cropper-dialog/image-cropper-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { Component, NgModule, Input, Inject, Output, EventEmitter, ViewChildren, ViewChild, AfterViewInit } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { SnackbarService } from '../snackbar/snackbar.service';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { take } from 'rxjs/operators';
import { UploadApiService } from '@smiths/data-access';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogService } from '../dialog/dialog.service';

@Component({
  selector: 'feature-photo-uploader',
  templateUrl: './photo-uploader.component.html',
  styleUrls: ['./photo-uploader.component.scss'],
})
export class PhotoUploaderComponent {
  @Input() control: FormControl | AbstractControl = new FormControl();
  @Input() addText = 'Add a logo';
  @Input() editText = 'Upload new logo';
  @Input() deleteText = 'Delete logo';
  @Input() placeholderIcon?: string;
  @Input() deleteDialogTitle = 'Delete logo?';
  @Input() deleteDialogMessage = 'Are you sure that you want to delete this logo?';
  @Input() readonly = false;

  @Output() uploaded = new EventEmitter();
  @Output() deleted = new EventEmitter();

  loading = false;

  constructor(
    public snackbarService: SnackbarService,
    private dialogService: DialogService,
    private uploadApiService: UploadApiService,
    private sanitizer: DomSanitizer,
    @Inject('apiBaseUrl') public apiBaseUrl: string
  ) {}

  onSelectImage(files: NgxFileDropEntry[]) {
    if (this.readonly) {
      return;
    }
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
            this.snackbarService.error('Format is not supported', 'Supported formats are .png and .jpeg.');
          } else {
            const reader = new FileReader();
            reader.onload = () => {
              this.loading = true;
              this.dialogService
                .imageCropperDialog({
                  image: file,
                })
                .afterClosed()
                .subscribe((res) => {
                  if (res?.result) {
                    this.uploadApiService
                      .uploadFile(res.image!)
                      .pipe(take(1))
                      .subscribe({
                        next: (result) => {
                          this.control.setValue(result[0]);
                          this.uploaded.emit();
                          this.loading = false;
                        },
                        error: () => {
                          this.loading = false;
                        },
                      });
                  } else {
                    this.loading = false;
                  }
                });
            };
            reader.readAsDataURL(file);
          }
        });
      }
    }
  }

  onDelete() {
    if (this.readonly) {
      return;
    }
    this.dialogService
      .prompt({ title: this.deleteDialogTitle, message: this.deleteDialogMessage, yesButtonText: 'Delete' })
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.control.setValue(undefined);
          this.deleted.emit();
        }
      });
  }

  onDisplay(url: string) {
    this.dialogService.photo({ photoUrl: this.apiBaseUrl + url });
  }
}

@NgModule({
  declarations: [PhotoUploaderComponent],
  imports: [CommonModule, NgxFileDropModule, MatSnackBarModule, MatIconModule, MatProgressSpinnerModule],
  exports: [PhotoUploaderComponent],
})
export class PhotoUploaderComponentModule {}
