import { CommonModule } from '@angular/common';
import { Component, NgModule, Input, AfterViewInit, Inject, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UploadedFile } from '@smiths/data-access';

declare global {
  interface Window {
    CI360: any;
  }
}

@Component({
  selector: 'ui-image360-viewer',
  templateUrl: './image360-viewer.component.html',
  styleUrls: ['./image360-viewer.component.scss'],
})
export class Image360ViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() images: UploadedFile[] = [];

  constructor(@Inject('apiBaseUrl') public apiBaseUrl: string) {}

  ngOnInit() {
    const urls = this.images.map((image) => this.apiBaseUrl + image.url);
    document.getElementById('cloudimage')?.setAttribute('data-image-list', JSON.stringify(urls));
  }

  ngAfterViewInit() {
    window.CI360.init();
  }

  ngOnDestroy() {
    window.CI360.destroy();
  }
}

@NgModule({
  declarations: [Image360ViewerComponent],
  imports: [CommonModule],
  exports: [Image360ViewerComponent],
})
export class Image360ViewerComponentModule {}
