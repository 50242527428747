import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StrapiApiService } from '../utils';
import { User } from '../models';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  constructor(
    private strapiApi: StrapiApiService,
    private http: HttpClient,
    private sharedService: SharedService
  ) {}

  getListForTable(param?: string) {
    return this.strapiApi.createGetRequest<User>(`/users${param ? '?' + param : ''}`);
  }

  getUser(id: number): Observable<User> {
    return this.http.get<User>(`/users/${id}`);
  }

  updateUser(id: number, model: Partial<User>) {
    return this.http.put<User>(`/edit-user/${id}`, model);
  }

  createUser(model: Partial<User>) {
    return this.http.post<User>(`/create-user`, model);
  }

  deleteUser(id: number) {
    return this.http.delete(`/delete-user/${id}`);
  }

  exportUsers() {
    return this.http.get<{ link: string }>(`/export-users`);
  }

  updateUsersStatus(ids: number[], blocked: 0 | 1) {
    return this.http.post(
      `/bloc-unblock-users`,
      this.sharedService.convertFormDataToFormUrl(
        this.sharedService.jsonToFormData({
          ...this.strapiApi.generateMultiIdBody(ids, 'users'),
          blocked,
        })
      ),
      {
        headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded'),
      }
    );
  }
}
