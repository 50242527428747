import { ControlsOf, FormGroup, FormControl } from '@ngneat/reactive-forms';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NavigationBarComponentModule,
  CheckboxGroupComponentModule,
  RadioComponentModule,
  UiButtonComponentModule,
  LoaderComponentModule,
} from '@smiths/ui';
import { CommonModule } from '@angular/common';
import { Component, NgModule, Output, EventEmitter } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SettingApiService } from '@smiths/data-access';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SnackbarService } from '../../../snackbar/snackbar.service';

export interface SettingFormControls {
  vaultUpdateReminderDuration: number;
  newUserNotification: boolean;
  vaultRequestNotification: boolean;
  vaultUpdateReminderNotification: boolean;
}

@Component({
  selector: 'feature-notification-setting',
  templateUrl: './notification-setting.component.html',
  styleUrls: ['./notification-setting.component.scss'],
})
export class NotificationSettingComponent {
  @Output() goToNotification = new EventEmitter();
  loading = false;
  saving = false;
  settingFormGroup?: FormGroup<ControlsOf<SettingFormControls>>;
  reminderDurationItems = [
    { text: 'Weekly', value: 7 },
    { text: 'Monthly', value: 30 },
    { text: 'After 2 month', value: 60 },
    { text: 'After 4 month', value: 120 },
  ];

  constructor(private settingService: SettingApiService, private snackBar: SnackbarService) {
    this.loading = true;
    this.settingService.getSettings().subscribe((result) => {
      this.settingFormGroup = new FormGroup({
        vaultUpdateReminderDuration: new FormControl(result.days_count_to_estimate ?? 30),
        newUserNotification: new FormControl(result.notification_for_joining_new_users ?? false),
        vaultRequestNotification: new FormControl(result.notification_for_initial_price_requests ?? false),
        vaultUpdateReminderNotification: new FormControl(false),
      });

      this.loading = false;
    });
  }

  onSaveClick() {
    if (!this.settingFormGroup || this.saving || this.loading) {
      return;
    }
    this.saving = true;
    const values = this.settingFormGroup!.value;
    this.settingService
      .updateSettings({
        notification_for_initial_price_requests: values.vaultRequestNotification,
        notification_for_joining_new_users: values.newUserNotification,
        days_count_to_estimate: values.vaultUpdateReminderDuration,
      })
      .subscribe(() => {
        this.goToNotification.emit();
        this.saving = false;
        this.snackBar.success('Changes Saved', 'Updated notification settings successfully.');
      });
  }
}

@NgModule({
  declarations: [NotificationSettingComponent],
  imports: [
    CommonModule,
    NavigationBarComponentModule,
    RadioComponentModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderComponentModule,
    MatProgressSpinnerModule,
    CheckboxGroupComponentModule,
    UiButtonComponentModule,
  ],
  exports: [NotificationSettingComponent],
})
export class NotificationSettingComponentModule {}
