import {
  Component,
  OnInit,
  NgModule,
  Input,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  ViewChildren, OnChanges, SimpleChanges
} from '@angular/core';
import { UploadedFile, VaultAnswer, VaultQuestionType, VaultStep, VaultStepModel } from '@smiths/data-access';
import { ComponentType } from '@angular/cdk/overlay';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import {
  CheckboxGroupComponent, DynamicComponent,
  FieldComponent,
  RadioComponent,
  SelectComponent,
  UiModule
} from '@smiths/ui';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ImageboxComponent } from '../imagebox/imagebox.component';

export interface VaultStepperStep {
  id?: number;
  stepName: string;
  stepNameArabic: string;
  stepImage: UploadedFile | null;
  questions: VaultStepperQuestion[];
  readonly?: boolean;
}
interface VaultStepperQuestion {
  id?: number;
  type: VaultQuestionType;
  component?: ComponentType<any>;
  title: string;
  titleArabic: string;
  question: string;
  questionArabic: string;
  options: { [p: string]: any };
  required?: boolean;
  className?: string;
  readonly?: boolean;
}
export interface VaultStepperStepModel {
  image: UploadedFile | null;
  name: string;
  nameArabic: string;
}
export interface VaultStepperQuestionModel {
  type: VaultQuestionType;
  title: string;
  titleArabic: string;
  question: string;
  questionArabic: string;
  options: { [p: string]: any };
  required?: boolean;
}

export interface VaultStepperSelectEvent {
  step: VaultStepperStep;
  stepIndex: number;
}

export interface VaultStepperEditEvent {
  stepIndex: number;
  questionIndex: number;
  question: VaultStepperQuestion;
}

@Component({
  selector: 'feature-vault-stepper',
  templateUrl: './vault-stepper.component.html',
  styleUrls: ['./vault-stepper.component.scss'],
})
export class VaultStepperComponent implements OnInit, OnChanges {
  @ViewChild('Stepper') stepper!: MatStepper;
  @ViewChildren(DynamicComponent) dynamicComponents?: DynamicComponent<any>[];

  @Input() title = 'Stepper';
  @Input() steps?: VaultStep[];
  @Input() answers?: VaultAnswer[];
  @Input() state: 'create' | 'edit' | 'view' = 'view';
  @Input() arabic = false;

  @Output() addStep = new EventEmitter();
  @Output() stepSelect = new EventEmitter<VaultStepperSelectEvent>();
  @Output() editQuestion = new EventEmitter<VaultStepperEditEvent>();

  currentSteps!: VaultStepperStep[];
  formGroup = new FormGroup({});
  selectedStepIndex = 0;
  selectedStepTotalQuestions = 0;
  selectedStep?: VaultStepperStep;

  constructor(@Inject('apiBaseUrl') public apiBaseUrl: string) {}

  ngOnInit(): void {
    this.initSteps();
    this.resolveCurrentSteps();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.arabic) {
      setTimeout(() => {
        this.dynamicComponents?.forEach(component => {
          try {
            component.invokeComponentFunction('switchLanguage', this.arabic);
          }catch {
            //
          }
        })
      }, this.dynamicComponents ? 1 : 0)
    }
  }

  onAddClick() {
    if (this.state === 'edit') {
      return;
    }

    this.addStep.emit();
    this.addNewStep({
      name: '',
      nameArabic: '',
      image: null,
    });
    setTimeout(() => {
      this.stepper.selectedIndex = this.currentSteps.length - 1;
    }, 10);
  }

  onQuestionEditClick(stepIndex: number, questionIndex: number, question: VaultStepperQuestion) {
    this.editQuestion.emit({
      stepIndex,
      questionIndex,
      question,
    });
  }

  onQuestionRemoveClick(stepIndex: number, questionIndex: number) {
    this.removeQuestion(stepIndex, questionIndex);
  }

  onStepChange(index: number) {
    this.selectStep(index);
  }

  addNewStep(model: VaultStepperStepModel) {
    this.currentSteps.push({
      stepName: model.name,
      stepNameArabic: model.nameArabic,
      stepImage: model.image,
      questions: [],
    });
  }

  unshiftStep(model: VaultStepperStep) {
    this.currentSteps.unshift(model);
    for (const question of this.currentSteps[0].questions) {
      this.resolveStep(this.currentSteps[0], question);
    }
    this.selectStep(0);
  }

  updateStep(model: VaultStepperStepModel, stepIndex?: number) {
    const stepToUse = stepIndex ?? this.selectedStepIndex;
    const step = this.currentSteps[stepToUse];
    step.stepName = model.name;
    step.stepNameArabic = model.nameArabic;
    step.stepImage = model.image;
    this.selectStep(stepToUse);
  }

  removeStep(stepIndex?: number) {
    const stepToUse = stepIndex ?? this.selectedStepIndex;
    if (this.currentSteps.length - 2 >= 0) {
      const previousIndex = this.stepper.selectedIndex;
      const previousStep = this.stepper.steps.get(previousIndex)!;
      this.stepper.selectedIndex = this.currentSteps.length - 2;
      const currentIndex = this.stepper.selectedIndex;
      const currentStep = this.stepper.steps.get(currentIndex)!;
      this.stepper.selectionChange.emit({
        previouslySelectedStep: previousStep,
        previouslySelectedIndex: previousIndex,
        selectedStep: currentStep,
        selectedIndex: currentIndex
      });
    }
    this.currentSteps.splice(stepToUse, 1);
  }

  addNewQuestion(model: VaultStepperQuestionModel, stepIndex?: number) {
    const stepToUse = stepIndex ?? this.selectedStepIndex;
    const step = this.currentSteps[stepToUse];
    step.questions.push(model);
    this.resolveStep(step, step.questions[step.questions.length - 1]);
  }

  updateQuestion(model: VaultStepperQuestionModel, stepIndex: number, questionIndex: number) {
    let question = this.currentSteps[stepIndex].questions[questionIndex];
    question = {
      ...question,
      title: model.title,
      titleArabic: model.titleArabic,
      question: model.question,
      questionArabic: model.questionArabic,
      required: model.required ?? false,
      options: {
        ...question.options,
        ...model.options,
      },
    };
    this.currentSteps[stepIndex].questions[questionIndex] = question;
  }

  removeQuestion(stepIndex: number, questionIndex: number) {
    this.currentSteps[stepIndex].questions.splice(questionIndex, 1);
  }

  exportSteps(): VaultStepModel[] {
    return this.currentSteps.filter(x => !x.readonly).map((step, stepIndex) => ({
      id: step.id,
      name_en: step.stepName,
      name_ar: step.stepNameArabic,
      image: step.stepImage?.id ?? undefined,
      order: stepIndex,
      questions: step.questions.map((question, questionIndex) => ({
        id: question.id,
        title: question.title,
        question: question.question,
        title_ar: question.titleArabic,
        question_ar: question.questionArabic,
        type: question.type,
        answers:
          question.type === 'checkbox' || question.type === 'dropdown' || question.type === 'radiobutton'
            ? question.options.items.map((x: any) => ({
                answer: x.text,
                answer_ar: x.textArabic,
              }))
            : undefined,
        input_type:
          question.type === 'textfield'
            ? question.options.inputType === 'text'
              ? 'string'
              : question.options.inputType === 'number'
              ? 'integer'
              : 'character'
            : undefined,
        number_of_images: question.type === 'imagebox' ? question.options.count : undefined,
        is_required: question.required ?? false,
        order: questionIndex,
      })),
    }));
  }

  private initSteps() {
    if (this.steps) {
      this.steps.sort((a, b) => (a.order > b.order ? 1 : -1));
      this.steps.forEach((step) => step.questions.sort((a, b) => (a.order > b.order ? 1 : -1)));
      this.currentSteps = this.steps.map((step) => ({
        id: step.id,
        stepName: step.name_en,
        stepNameArabic: step.name_ar,
        stepImage: step.image,
        questions: step.questions.map((question) => ({
          id: question.id,
          type: question.type,
          title: question.title,
          titleArabic: question.title_ar,
          question: question.question,
          questionArabic: question.question_ar,
          required: question.is_required,
          options: {
            inputType: question.input_type
              ? question.input_type === 'string'
                ? 'text'
                : question.input_type === 'integer'
                ? 'number'
                : 'character'
              : undefined,
            count: question.number_of_images ?? undefined,
            items:
              question.answers.length > 0
                ? question.answers.map((x) => ({
                    text: x.answer,
                    textArabic: x.answer_ar,
                    name: x.answer,
                    value: x.answer,
                  }))
                : undefined,
          },
        })),
      }));
    } else {
      // Use predefined steps when creating a new stepper (Fixed Step)
      this.currentSteps = [];
    }
    this.selectStep(0);
  }

  private selectStep(index: number) {
    if (index >= this.currentSteps?.length) {
      return;
    }
    this.selectedStepIndex = index;
    this.selectedStepTotalQuestions = this.currentSteps[index].questions.length;
    this.selectedStep = this.currentSteps[index];
    this.stepSelect.emit({
      stepIndex: index,
      step: this.selectedStep,
    });
  }

  private resolveCurrentSteps() {
    for (const step of this.currentSteps) {
      for (const question of step.questions) {
        this.resolveStep(step, question);
      }
    }
  }

  private resolveStep(step: VaultStepperStep, question: VaultStepperQuestion) {
    // Auto generate 'control' and 'component' values
    const control = this.createControl(step.stepName, question);
    let component;
    switch (question.type) {
      case 'checkbox':
        component = CheckboxGroupComponent;
        question.className = 'mb-6';
        break;
      case 'dropdown':
        component = SelectComponent;
        break;
      case 'textfield':
        component = FieldComponent;
        break;
      case 'imagebox':
        component = ImageboxComponent;
        question.className = 'mb-6';
        break;
      case 'radiobutton':
        component = RadioComponent;
        question.className = 'mb-6';
        break;
    }
    question.options = {
      ...question.options,
      control,
      readonly: this.state === 'view'
    };
    question.component = component;
  }

  private createControl(stepName: string, question: VaultStepperQuestion) {
    const validators = [];
    if (question.required) {
      validators.push(Validators.required);
    }
    const control = new FormControl(undefined, []);
    // if (this.state === 'view') {
    //   this.formGroup.addControl(this.generateControlUniqueName(stepName, question.title), control);
    // }
    if (this.answers) {
      const answerList = this.answers.filter(x => x.question.id === question.id);
      if (answerList.length === 0) {
        // Do nothing...
      } else if (answerList[0]?.text_answer) {
        control.setValue(answerList[0].text_answer);
      } else if (answerList[0]?.selection_answer) {
        control.setValue(
          answerList[0].question.type === 'radiobutton'
            ? answerList[0].selection_answer?.answer
            : answerList.map(x => x.selection_answer?.answer)
        );
        console.log(control.value);
      } else if (answerList[0]?.images) {
        control.setValue(answerList[0].images);
      }
    }
    return control;
  }

  private generateControlUniqueName(stepName: string, title: string) {
    return `${stepName}_${title}`;
  }
}

@NgModule({
  declarations: [VaultStepperComponent],
  imports: [CommonModule, MatStepperModule, UiModule, MatButtonModule],
  exports: [VaultStepperComponent],
})
export class VaultStepperComponentModule {}
