import {
  Component,
  OnInit,
  NgModule,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Observable, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

export interface SelectItem<T> {
  text?: string;
  value: T | null;
  textArabic?: string;
}

@Component({
  selector: 'ui-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent<T> implements OnInit, OnDestroy, OnChanges {
  @Input() control = new FormControl(null);
  @Input() items!: Observable<SelectItem<T>[]> | SelectItem<T>[];
  @Input() label = '';
  @Input() placeholder = '';
  @Input() appearance: 'filled' | 'stroked' = 'stroked';
  @Input() mini = false;
  @Input() noValue?: string;
  @Input() resetAfterSelect = false;
  @Input() readonly  = false;

  @Output() itemSelect = new EventEmitter<T>();

  currentItems?: SelectItem<T>[];
  sub?: Subscription;
  isArabic = false;

  ngOnInit(): void {
    this.updateCurrentItemsList();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.sub?.unsubscribe();
      this.currentItems = undefined;
      this.updateCurrentItemsList();
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  onItemSelect(e: T) {
    this.itemSelect.emit(e);
    if (this.resetAfterSelect) {
      this.control.reset();
    }
  }

  switchLanguage(arabic: boolean) {
    this.isArabic = arabic;
  }

  private updateCurrentItemsList() {
    if (this.items instanceof Observable) {
      this.sub = this.items.subscribe((result) => {
        this.currentItems = result;
        this.addNoValueOption();
      });
    } else {
      this.currentItems = this.items;
      this.addNoValueOption();
    }
  }
  private addNoValueOption() {
    if (this.currentItems && this.noValue && !this.currentItems.find((x) => x.value === null)) {
      this.currentItems.unshift({
        text: this.noValue,
        value: null,
      });
    }
  }
}

@NgModule({
  declarations: [SelectComponent],
  imports: [MatFormFieldModule, MatSelectModule, CommonModule, ReactiveFormsModule, MatIconModule],
  exports: [SelectComponent],
})
export class SelectComponentModule {}
