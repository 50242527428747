import { Component, NgModule } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@smiths/data-access';
import { UiModule } from '@smiths/ui';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'smiths-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  formGroup: FormGroup;
  loading = false;
  token: string;
  validPattern = environment.passwordPattern;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {
    this.token = this.route.snapshot.queryParams.token;
    if (!this.token) {
        this.router.navigate(['auth']);
    }

    this.formGroup = new FormGroup({
      password: new FormControl('',Validators.compose([Validators.required, Validators.pattern(this.validPattern)]))
    })
   }

  resetPassword(){
    const val = this.formGroup.value
    this.authService.resetPassword(this.token, val.password).subscribe(
      res => {
        console.log(res)
      }
    )
  }

}

@NgModule({
  declarations: [ResetPasswordComponent],
  imports: [UiModule,ReactiveFormsModule],
  exports: [ResetPasswordComponent]
})
export class ResetPasswordComponentModule {
}
