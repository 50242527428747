import {
  Component,
  NgModule,
  Input,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter, OnInit, OnDestroy
} from '@angular/core';
import { NgxMatIntlTelInputComponent, NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { Country } from 'ngx-mat-intl-tel-input/lib/model/country.model';
import { PhoneNumber } from 'libphonenumber-js';
import { Subscription } from 'rxjs';

export interface PhoneInput {
  countryCode: string;
  phoneNumber: string;
  fullNumber: string;
}

@Component({
  selector: 'ui-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('Wrapper') wrapperEl?: ElementRef<HTMLElement>;
  @ViewChild('Field') field!: NgxMatIntlTelInputComponent;

  @Input() control = new FormControl('');
  @Input() label = 'Phone number';

  @Output() countryChange = new EventEmitter<Country>();
  @Output() valueChange = new EventEmitter<PhoneInput>();

  selectedCountry?: Country;
  subs = new Subscription();

  ngOnInit() {
    this.subs.add(this.control.valueChanges.subscribe(() => {
      this.emitValueChange();
    }));
  }

  ngAfterViewInit() {
    this.selectedCountry = this.field.selectedCountry;
    this.countryChange.emit(this.selectedCountry);
    this.emitValueChange();
    this.computeInputMargin();
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  countryChanged(e: Country) {
    this.selectedCountry = e;
    this.countryChange.emit(this.selectedCountry);
    this.computeInputMargin();
  }

  private emitValueChange() {
    if (this.field && this.field.numberInstance) {
      this.valueChange.emit({
        countryCode: '+' + this.field.numberInstance.countryCallingCode as string,
        phoneNumber: this.field.numberInstance.nationalNumber as string,
        fullNumber: this.field.numberInstance.number as string,
      });
    }
  }

  private computeInputMargin() {
    if (!this.wrapperEl) {
      return;
    }
    setTimeout(() => {
      const codeEl = this.wrapperEl?.nativeElement.getElementsByClassName('country-selector-code')[0];
      const inputEl = this.wrapperEl?.nativeElement.getElementsByTagName('input')[0];
      (inputEl as HTMLInputElement).style.marginLeft = `${Math.ceil((codeEl as Element).clientWidth) - 16}px`;
    }, 2);
  }
}

@NgModule({
  declarations: [PhoneInputComponent],
  imports: [NgxMatIntlTelInputModule, ReactiveFormsModule, MatFormFieldModule, CommonModule],
  exports: [PhoneInputComponent],
})
export class PhoneInputComponentModule {}
