import {
  ChangeDetectorRef,
  Directive,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { MatStep } from '@angular/material/stepper';
import { CdkStepper } from '@angular/cdk/stepper';
import { Subscription } from 'rxjs';

const DEFAULT_IMAGE = '/assets/ui/icons/images.svg';

@Directive({
  selector: '[uiStepExtend]',
})
export class StepExtendDirective implements OnChanges, OnDestroy {
  @Input() useCustomImage = false;
  @Input() image: string | null | undefined = DEFAULT_IMAGE;
  stepper: CdkStepper;
  currentIndex = 0;
  currentImage?: string | null;
  imageElement?: HTMLImageElement;
  subs = new Subscription();
  constructor(private host: MatStep) {
    this.stepper = this.host._stepper;
    this.subs.add(
      this.stepper.steps.changes.subscribe(() => {
        for (let i = 0; i < this.stepper.steps.length; i++) {
          const step = this.stepper.steps.get(i);
          if (step === this.host) {
            this.currentIndex = i;
            this.createImageElement();
          }
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.image) {
      this.setStepImage();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private createImageElement() {
    if (!this.useCustomImage && !this.imageElement) {
      return;
    }
    setTimeout(() => {
      // @ts-ignore
      const elementRef = this.stepper._elementRef.nativeElement as HTMLElement;
      const query = `mat-step-header[ng-reflect-index="${this.currentIndex}"]`;
      const stepHeaderElement = elementRef.querySelectorAll(`mat-step-header`)[this.currentIndex] as HTMLElement;
      const iconElement = stepHeaderElement.querySelector('.mat-step-icon') as HTMLElement;
      const iconContentElement = stepHeaderElement.querySelector('.mat-step-icon-content') as HTMLElement;
      iconContentElement.style.display = 'none';
      this.imageElement = document.createElement('img');
      this.imageElement.style.width = '100%';
      this.imageElement.style.padding = '0.375rem';
      iconElement.appendChild(this.imageElement);
      iconElement.style.overflow = 'hidden';
      this.setStepImage();
    }, 1);
  }

  private setStepImage() {
    if (this.image === null || this.image === undefined) {
      this.image = DEFAULT_IMAGE;
    }
    if (this.currentImage === this.image || !this.imageElement) {
      return;
    }
    this.currentImage = this.image;
    this.imageElement!.src = this.currentImage;
  }
}

@NgModule({
  declarations: [StepExtendDirective],
  imports: [],
  exports: [StepExtendDirective],
})
export class StepExtendDirectiveModule {}
