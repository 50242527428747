import { DialogService } from '@smiths/feature';
import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CanDeactivateComponent } from '../models/can-deactivate.model';

@Injectable({
  providedIn: 'root',
})
export class UnsavedDataGuard implements CanDeactivate<any> {
  constructor(private dialog: DialogService) {}
  canDeactivate(
    component: CanDeactivateComponent
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!(component?.canDeactivate)) {
      return true
    }
    const data = component.canDeactivate();
    if (data.pass) {
      return true;
    }
    return this.dialog.prompt({
      title: `Leave without saving this ${ data.pageName }?`,
      message: 'Are you sure you want to leave without saving steps?',
      yesButtonText: `Leave ${ data.pageName }`,
    }).afterClosed().pipe(
      take(1),
      map((x) => x ?? false)
    );
  }
}
