<div class="desktop-layout">
    
    <div class='col-span-1 bg-primary-800 flex items-center px-6'>
        <img [routerLink]="['./']" class="absolute top-6 h-4" src="../../../assets/images/smiths.svg">
        <img src="../../../assets/images/auth-layout-side.svg"></div>

    <div class='col-span-2'>
        <router-outlet></router-outlet>
    </div>
</div>

<div class="mobile-layout">
    <img [routerLink]="['./']" class="absolute top-6 left-6 h-4" src="../../../assets/images/smiths-colored.svg">
    <router-outlet></router-outlet>
</div>