<div style="border-radius: 0.625rem" class="flex p-3 w-full gap-4 {{ data.bgClass }}">
  <div>
    <mat-icon class="w-8 h-8 mt-2 {{ data.iconClass ?? data.textClass }}" [svgIcon]="data.icon"></mat-icon>
  </div>
  <div class="flex flex-col {{ data.textClass }}">
    <p class="text-xl font-medium leading-normal lexend-deca">{{ data.title }}</p>
    <p class="text-base leading-normal">{{ data.message }}</p>
  </div>
  <mat-icon
    (click)="close()"
    svgIcon="close"
    class="{{ data.textClass }} cursor-pointer ml-5 flex-none h-4 w-4"
  ></mat-icon>
</div>
