import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard, AuthGuard } from '@smiths/utility';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { ResetLinkSentComponent } from './pages/auth';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },

  {
    path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'link-sent',
        component: ResetLinkSentComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      }
    ]
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate:[AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard-shell/dashboard-shell.component').then((m) => m.DashboardShellComponentModule),
      },
      {
        path: 'vault',
        loadChildren: () =>
          import('./pages/vault/vault-shell/vault-shell.component').then((m) => m.VaultShellComponentModule),
      },
      {
        path: 'showcase',
        loadChildren: () =>
          import('./pages/showcase/showcase-shell/showcase-shell.component').then((m) => m.ShowcaseShellComponentModule),
      },
      {
        path: 'users',
        loadChildren: () => import('../app/pages/users/users-shell/users-shell.component').then((m) => m.UsersShellComponentModule),
      },
      {
        path: 'affiliates',
        loadChildren: () => import('../app/pages/affiliates/affiliates-shell/affiliates-shell.component').then((m) => m.AffiliatesShellComponentModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('../app/pages/notifications/notifications-shell/notifications-shell.component').then((m) => m.NotificationsShellComponentModule)
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
