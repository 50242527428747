import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(@Inject('apiBaseUrl') private apiBaseUrl: string,private httpClient: HttpClient) {}

  login(email: string, password: string, fcmToken = 'xxxxx'): Observable<any> {
    const searchParams = new URLSearchParams();
    searchParams.append('email', email);
    searchParams.append('password', password);
    searchParams.append('fcm', fcmToken);
    searchParams.append('uuid', fcmToken);

    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.httpClient.post('/login-with-email', searchParams, { headers: headerOptions });
  }

  sendResetLink(email: string) {
    const searchParams = new URLSearchParams();
    searchParams.append('email', email);
    searchParams.append('base_url', this.apiBaseUrl + '/auth/reset-password');

    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.httpClient.post('/send-reset-password-link', searchParams, { headers: headerOptions });
  }

  userResetPassword(token: string, password: string) {
    const form = new FormData();
    form.append('token', token);
    form.append('password', password);
    return this.httpClient.post(`/reset-password`, form);
  }

  userChangePassword(currentPassword: string, newPassword: string) {
    const form = new FormData();
    form.append('last_password', currentPassword);
    form.append('new_password', newPassword);
    return this.httpClient.post(`/change-password`, form);
  }

  updateProfileAvatar(avatar: number) {
    const form = new FormData();
    form.append('avatar', avatar ? avatar.toString() : '');
    return this.httpClient.post<User>(`/edit-personal-info`, form);
  }
}
