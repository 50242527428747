import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StrapiApiService } from '../utils';
import { VaultRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class VaultRequestsApiService {
  constructor(private http: HttpClient, private strapiApi: StrapiApiService) {}

  getListForTable(userId?: number, vaultId?: number, param?: string) {
    let params = '';
    if (userId) {
      params = `?user=${userId}`;
    } else if (vaultId) {
      params = `?vault=${vaultId}`;
    } else if (param) {
      params = `?${param}`;
    }
    return this.strapiApi.createGetRequest(`/requests${params}`);
  }

  getRequest(requestId: number) {
    return this.http.get<VaultRequest>(`/requests/${requestId}?currency=KWD`);
  }

  setEstimatedPrice(requestId: number, price: number) {
    const params = new URLSearchParams();
    params.append('request', String(requestId));
    params.append('price', String(price));

    const headerOptions = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post('/estimated-prices', params, { headers: headerOptions });
  }

  exportData() {
    return this.http.get<{ link: string }>(`/requests-export`);
  }
}
