<p class="lexend-deca text-2xl text-gray-800">{{ data.title }}</p>
<p class="mt-4 text-gray-800 font-medium">{{ data.message }}</p>
<!--<div class="flex mt-4 gap-4" [class.pr-8]="data.compact">-->
<!--  <ui-button class="w-{{ data.compact ? 'full' : '40'}}" [label]="data.noButtonText ?? 'Cancel'" appearance="stroked" (click)="closeDialog(false)"></ui-button>-->
<!--  <ui-button class="w-{{ data.compact ? '36' : '40'}}" [label]="data.yesButtonText ?? 'Proceed'" color="primary" (click)="closeDialog(true)"></ui-button>-->
<!--</div>-->
<div class="flex mt-4 gap-4 pr-8">
  <ui-button
    class="w-full"
    [label]="data.noButtonText ?? 'Cancel'"
    appearance="stroked"
    (click)="closeDialog(false)"
  ></ui-button>
  <ui-button
    class="w-36"
    [label]="data.yesButtonText ?? 'Proceed'"
    color="primary"
    (click)="closeDialog(true)"
  ></ui-button>
</div>
