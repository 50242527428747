import { Directive, ElementRef, HostBinding, HostListener, NgModule, OnDestroy } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img[imageLoader]'
})
export class ImageLoaderDirective implements OnDestroy {
  @HostBinding('style.display') display = 'block';
  @HostBinding('style.opacity') opacity = 0;

  private changes: MutationObserver;

  constructor(private host: ElementRef) {
    this.changes = new MutationObserver((mutations: MutationRecord[]) =>
      mutations.filter(m => m.attributeName === 'src').forEach(() => this.opacity = 0)
    );

    this.changes.observe(this.host.nativeElement, {
      attributes: true,
      childList: false,
      characterData: false
    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  @HostListener('load')
  onLoad(): void {
    this.opacity = 1;
  }
}

@NgModule({
  declarations: [ImageLoaderDirective],
  imports: [],
  exports: [ImageLoaderDirective]
})
export class ImageLoaderDirectiveModule {
}
