<button
  [class.inline-text]="wrappingText"
  [disabled]="disabled"
  mat-flat-button
  [class.pointer-events]="disabled === true"
  [class.primary]="color === 'primary'"
  [class.secondary]="color === 'secondary'"
  [class.warn]="color === 'warn'"
  [class.stroked]="appearance === 'stroked'"
  [class.filled]="appearance === 'filled'"
  [class.px-2]="isAction && !isLoading"
  [ngClass]="{ 'action w-inherit h-7': isAction, 'px-8 py-2 w-inherit h-inherit': !isAction }"
  [routerLink]="clickRouterLink"
  (click)="clicked.emit()"
>
  <div class="flex flex-row items-center w-full h-full justify-center">
    <ng-container *ngIf="!isLoading; else Loading">
      <mat-icon class="mr-2" *ngIf="icon" [svgIcon]="icon"></mat-icon>
      <ng-container>{{ label | titlecase }}</ng-container>
    </ng-container>
    <ng-template #Loading>
      <mat-spinner color="primary" diameter="16" class="mx-2"></mat-spinner>
    </ng-template>
  </div>
</button>
