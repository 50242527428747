import {
  Component,
  OnInit,
  NgModule,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AbstractControl, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { merge, of, Subscription } from 'rxjs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { DragScrollModule } from 'ngx-drag-scroll';

export interface DateRange {
  start: Date;
  end: Date;
}
export type FieldInputType = 'text' | 'password' | 'number';

@Component({
  selector: 'ui-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss']
})

export class FieldComponent implements OnInit, OnDestroy {
  @Input() control = new FormControl('');
  @Input() arabicControl?: FormControl;
  @Input() endControl?: FormControl;
  @Input() inputType: FieldInputType = 'text';
  @Input() controlType: 'input' | 'date-range' = 'input';
  @Input() label?: string;
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() prefixIcon?: string;
  @Input() suffixIcon?: string;
  @Input() compact = false;
  @Input() mini = false;
  @Input() showError = true;
  @Input() customErrorText?: string;
  @Input() multipleDateRanges = false;
  @Input() dateRangeChipsControl?: FormControl;

  @Output() addRange = new EventEmitter<DateRange>();
  @Output() removeRange = new EventEmitter<DateRange>();

  currentError = '';
  sub = new Subscription();
  arabicSelected = false;
  showPassword = false;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.sub.add(merge(this.control.statusChanges, this.arabicControl?.statusChanges ?? of('VALID')).subscribe(status => {
      if (status === 'INVALID') {
        this.getErrorText();
      }
      this.control.updateValueAndValidity({ emitEvent: false });
      this.arabicControl?.updateValueAndValidity({ emitEvent: false });
    }));
    // if (this.arabicControl) {
    //   this.sub.add(merge(this.control.valueChanges))
    // }


    if (this.arabicControl) {
      this.control.addValidators(this.ValidateBind(this.arabicControl));
      this.arabicControl.addValidators(this.ValidateBind(this.control));
    }
  }
  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
  selectEnglish() {
    this.arabicSelected = false;
    this.getErrorText();
    this.cdr.detectChanges();
  }
  selectArabic() {
    this.arabicSelected = true;
    this.getErrorText();
    this.cdr.detectChanges();
  }

  private getErrorText() {
    if (this.hasError('required')) {
      this.currentError = 'This input is required.';
    } else if (this.hasError('email')) {
      this.currentError = 'This Email is not valid.';
    } else if (this.hasError('bind')) {
      this.currentError = 'Please fill in two languages.'
    } else {
      this.currentError = 'Field is invalid.'
    }
  }
  private hasError(key: string) {
    return this.arabicSelected ? this.arabicControl?.hasError(key) : this.control.hasError(key);
  }
  private ValidateBind = (control: FormControl) => (c: AbstractControl) => {
    const otherControlErrorCount = control.errors ? Object.keys(control.errors as any).length : 0;
    return c && control ? control.valid || (otherControlErrorCount === 1 && control.hasError('bind')) ? null : { bind: true } : null;
  }
  onAddRange() {
    if (this.multipleDateRanges) {
      this.addRange.emit({start: this.control.value, end: this.endControl?.value});
      this.control.setValue('');
      this.endControl?.setValue('');

    }
  }
  onDeleteRange(range: DateRange) {
    this.removeRange.emit(range);
  }
  isDateRangeChipsInvalid() {
    return this.dateRangeChipsControl?.invalid && this.dateRangeChipsControl?.touched;
  }
  clearDateRangePicker() {
    this.control.setValue('');
    this.endControl?.setValue('');
  }
}

@NgModule({
  declarations: [FieldComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDatepickerModule,
    MatButtonModule,
    MatChipsModule,
    DragScrollModule,
  ],
  exports: [FieldComponent]
})
export class FieldComponentModule {
}
