<mat-form-field
  class="ui-select"
  appearance="outline"
  [class.fill-style]="appearance === 'filled'"
  [class.stroke-style]="appearance === 'stroked'"
  [class.mini-style]="mini"
>
  <p class="select-label" *ngIf="label">{{ label }}</p>
  <div
    *ngIf="noValue && (control.value === null || control.value === undefined || control.value === '')"
    class="no-value-label"
  >
    {{ noValue }}
  </div>
  <mat-select
    (selectionChange)="onItemSelect($event.value)"
    [formControl]="control"
    placeholder="{{ placeholder }} {{ !currentItems ? ' (Loading...)' : '' }}"
    disableOptionCentering
  >
    <ng-container *ngIf="currentItems">
      <mat-option
        [class.pointer-events-none]="readonly"
        class="ui-select-option"
        *ngFor="let item of currentItems"
        [value]="item.value"
        >{{ (isArabic ? item.textArabic : item.text) | titlecase }}</mat-option
      >
    </ng-container>
  </mat-select>
  <div class="absolute top-0 bottom-0 right-4 flex items-center justify-center">
    <mat-icon
      class="w-{{ mini ? 2 : 4 }} h-{{ mini ? 2 : 4 }} text-gray-700"
      svgIcon="arrow-bottom"
    ></mat-icon>
  </div>
</mat-form-field>
<ng-template #MiniSelect>
  <div class="ui-select-mini"></div>
</ng-template>
