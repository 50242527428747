import { VaultRequest } from './vault.model';

export interface NotificationList {
  unread_count: number;
  total_count:number;
  list: Notification[];
}
export interface Notification {
  body: string;
  created_at: string;
  created_by: any | null;
  id: number;
  new_user: number | null
  read: boolean;
  receiver: number;
  request: VaultRequest | null
  title: string;
  updated_at: string;
  updated_by: any | null
  state : 'vault_request' | 'new_user' | 'update_vault'
}

export interface NotificationUnreadCount{
  unread_count:number,
}
