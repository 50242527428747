import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { TableComponentModule } from './table/table.component';
import { PhotoUploaderComponentModule } from './photo-uploader/photo-uploader.component';
import { MapComponentModule } from './map/map.component';
import { VaultStepperComponentModule } from './vault-stepper/vault-stepper.component';
import { ImageboxComponentModule } from './imagebox/imagebox.component';

@NgModule({
  imports: [CommonModule, MatSnackBarModule, MatIconModule, MatDialogModule],
  exports: [
    MatSnackBarModule,
    MatIconModule,
    MatDialogModule,
    TableComponentModule,
    PhotoUploaderComponentModule,
    MapComponentModule,
    VaultStepperComponentModule,
    ImageboxComponentModule
  ],
  declarations: [],
})
export class FeatureModule {}
