import { Directive, ElementRef, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[uiStepperExtend]'
})
export class StepperExtendDirective implements OnInit, OnDestroy {
  @Input() activeStepClass?: string;
  activeStepClassList: string[] = [];
  steps: HTMLElement[] = [];
  subs = new Subscription();
  currentIndex?: number;
  constructor(private host: MatStepper, private elementRef: ElementRef<HTMLElement>) {
    this.subs.add(this.host.steps.changes.subscribe(e => {
      setTimeout(() => {
        this.steps = Array.from(this.elementRef.nativeElement.getElementsByClassName('mat-step')) as HTMLElement[];
        this.updateSteps();
      }, 1)
    }))
    this.subs.add(this.host.selectionChange.subscribe(e => {
      if (this.activeStepClass) {
        const prevStep = this.steps[e.previouslySelectedIndex];
        const curStep = this.steps[e.selectedIndex];
        prevStep.classList.remove(...this.activeStepClassList);
        curStep.classList.add(...this.activeStepClassList);
        this.currentIndex = e.selectedIndex;
      }
    }));
  }
  ngOnInit() {
    if (this.activeStepClass) {
      this.activeStepClassList = this.activeStepClass.split(' ');
      this.updateSteps();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private updateSteps() {
    this.steps.forEach((step, index, arr) => {
      step.classList.add('relative');
    });
    if (this.steps.length >= 1 && this.activeStepClass && !this.currentIndex) {
      this.steps[0].classList.add(...this.activeStepClassList);
    }
  }
}

@NgModule({
  declarations: [StepperExtendDirective],
  imports: [],
  exports: [StepperExtendDirective]
})
export class StepperExtendDirectiveModule {
}
