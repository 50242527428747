// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'smiths-18f3c',
    appId: '1:145211263035:web:60aaa9789cc52be762b41e',
    storageBucket: 'smiths-18f3c.appspot.com',
    apiKey: 'AIzaSyC-W8NfmI_VW7gc6kIFutfzolB1qGgFmx4',
    authDomain: 'smiths-18f3c.firebaseapp.com',
    messagingSenderId: '145211263035',
    measurementId: 'G-LD9CPD4HYS',
  },
  apiBaseUrl: 'https://smith.adad.ws',
  production: false,
  passwordPattern: '^[a-zA-Z0-9]{8,}$',
  vapidKey: 'BNjmbClqEa0IhHMZ1ml3jOTy2DhzPkNDgz4onZ-8jgtNUykX3DUdsP_jzuemQnAy6hrLiQTNFfrmTks8gvN-iJU',
  googleMapAPIKey: 'AIzaSyDl8Ae8ELo5_vwATYaqoi7qHAPirGNJhpI'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
