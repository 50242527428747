import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private httpClient: HttpClient) {}

  convertFormDataToFormUrl(formData: FormData): string {
    const httpParams: string[] = [];
    formData.forEach((value, key) => {
      httpParams.push(`${key}=${value.toString()}`);
    });
    return httpParams.join('&');
  }

  jsonToFormData(object: any) {
    const formData = new FormData();
    for(const key of Object.keys(object)) {
      if (object[key] !== undefined) {
        formData.append(key, object[key]);
      }
    }
    return formData;
  }

  delete<T>(url: string, ids: number[], responseType: 'json' | 'text' = 'json') {
    const formData = new FormData();
    ids?.forEach((id, index) => {
      formData.append(`ids[${index}]`, id.toString());
    });
    const formUrl = this.convertFormDataToFormUrl(formData);
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded'),
      responseType: responseType as never,
    };
    return this.httpClient.put<T>(url, formUrl, options);
  }
}
