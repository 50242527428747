import { Component, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'smiths-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent {

  constructor(private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }



}

@NgModule({
  declarations: [AuthLayoutComponent],
  imports: [RouterModule],
  exports: [AuthLayoutComponent]
})
export class AuthLayoutComponentModule {
}
