import { Component, NgModule, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UiModule } from '@smiths/ui';

export interface PhotoDialogData {
  photoUrl: string;
}

@Component({
  selector: 'feature-photo-dialog',
  templateUrl: './photo-dialog.component.html',
  styleUrls: ['./photo-dialog.component.scss'],
})
export class PhotoDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<PhotoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PhotoDialogData
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  onDownload() {
    window.open(this.data.photoUrl);
  }
}

@NgModule({
  declarations: [PhotoDialogComponent],
  imports: [MatIconModule, UiModule],
  exports: [PhotoDialogComponent],
})
export class PhotoDialogComponentModule {}
