import { UploadedFile } from './uploaded-file.model';

export interface ShowcaseList {
  list: Showcase[];
}

export interface Showcase {
  id: number;
  published_at: string;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  en_name: string;
  ar_name: string;
  phone: string;
  email: string;
  instagram: string;
  latitude: number;
  longitude: number;
  image_360: number;
  status: ShowcaseStatus;
  logo: UploadedFile;
  timescales: Timescale[];
  address: string;
}

export interface ShowcaseDetails {
  id: number;
  published_at: string;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  en_name: string;
  ar_name: string;
  phone: string;
  email: string;
  instagram: string;
  latitude: number;
  longitude: number;
  image_360: Image360;
  status: ShowcaseStatus;
  logo: UploadedFile;
  timescales: Timescale[];
  address: string;
  normal_images: UploadedFile[];
}

export interface Image360 {
  id: number;
  shop: null;
  published_at: string;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  name: string;
  images: UploadedFile[];
}

export interface Timescale {
  id: number;
  start_date: string;
  end_date: string;
  shop: number;
  published_at: string;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
}

export interface ShowcaseModel {
  timescales?: TimescaleModel[];
  en_name?: string;
  ar_name?: string;
  phone?: string;
  email?: string;
  instagram?: string;
  latitude?: number;
  longitude?: number;
  logo?: number;
  image_360?: Image360Model | null;
  normal_images?: number[];
  status?: ShowcaseStatus;
  address?: string;
}

export type ShowcaseStatus = 'enable' | 'disable';

export interface Image360Model {
  images: number[];
  name: string;
}

export interface TimescaleModel {
  start_date: string;
  end_date: string;
}
