import { UploadedFile } from './uploaded-file.model';


export interface AffiliateList {
  list: Affiliate[];
}

export interface Affiliate {
  id: number;
  en_name: string;
  phone: string;
  ar_name: string;
  instagram: string;
  en_category: string;
  ar_category: string;
  email: string;
  latitude: number;
  longitude: number;
  published_at: string;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  status: AffiliateStatus;
  logo: UploadedFile;
  address: string;
}

export interface AffiliateModel {
  en_name: string;
  ar_name: string;
  phone: string;
  email: string;
  instagram: string;
  latitude: number;
  longitude: number;
  en_category: string;
  ar_category: string;
  logo: number;
  status: AffiliateStatus;
  address: string;
}

export type AffiliateStatus = 'enable' | 'disable';
