import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StrapiApiService } from '../utils';
import { Vault, VaultList, VaultModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class VaultApiService {

  constructor(private http: HttpClient, private strapiApi: StrapiApiService) { }

  getListForTable() {
    return this.strapiApi.createGetRequest<Vault>('/vaults');
  }

  createVault(model: VaultModel) {
    return this.http.post<Vault>(`/vaults`, model);
  }

  updateVault(vaultId: number, model: VaultModel) {
    return this.http.put<Vault>(`/vaults/${vaultId}`, model);
  }

  getVault(vaultId: number) {
    return this.http.get<Vault>(`/vaults/${vaultId}`);
  }

  deleteVault(ids: number[]) {
    return this.http.delete(`/vaults?${this.strapiApi.generateMultiIdParam(ids)}`);
  }

  exportData() {
    return this.http.get<{ link: string }>(`/export-vaults`);
  }
}
